import React from "react";

import { GridLoader as RSGridLoader } from "react-spinners";

const GridLoader = ({ padded = false }) => {
  return (
    <div
      className={"flex justify-center w-full" + (padded === true ? "p-16" : "")}
      style={{ gridColumn: "1/-1" }}
    >
      <RSGridLoader
        color={"#666"}
        loading={true}
        size={8}
        margin={4}
        css={
          "display: grid; grid-template-columns: repeat(3, 1fr); height: 48px;"
        }
      />
    </div>
  );
};

export default GridLoader;
