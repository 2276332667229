import React from "react";

const DotIndicator = ({ amount, current }) => {
  return (
    <>
      {Array(amount).map((_, i) => {
        <div className={"border border-black rounded-full " + (current === i ? "bg-black" : "bg-transparent")}></div>
      })}
    </>
  )
}

export default DotIndicator;