import "./EventList.css";

import React, { useState, useEffect, useRef } from "react";

import JobsMap from "./JobsMap";
import Select from "../ui/Select";
import { buildQueryParams } from "../helpers/query";
import client from "../api/client";
import searchIcon from "../assets/ui/search-white.png";
import useDeepCompareEffect from "use-deep-compare-effect";

import InfiniteScroll from "react-infinite-scroller";

import Job from "./Job";
import GridLoader from "./GridLoader";

import ModalFilter from "./filters/ModalFilter/ModalFilter";
import { useWindowSize } from "../utils";

const JobsList = (props) => {
  const [jobs, setJobs] = useState(props.jobs);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const displayModal = () => {
    if (!showFilterModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setShowFilterModal(!showFilterModal);
  };

  const [city, setCity] = useState({});
  const [position, setPosition] = useState({});
  const [category, setCategory] = useState({});
  const [showMap, setShowMap] = useState(props.showMap || false);
  const [loading, setLoading] = useState(true);
  const [donePaginating, setDonePaginating] = useState(false);
  const listRef = useRef();

  const modalItems = [
    {
      type: "select",
      placeholder: "City",
      value: city,
      options: props.cities,
      method: setCity,
    },
    {
      type: "select",
      placeholder: "Position",
      value: position,
      options: props.positions,
      method: setPosition,
    },
    {
      type: "select",
      placeholder: "Job Type",
      value: category,
      options: props.categories,
      method: setCategory,
    },
  ];

  const getJobs = (page = 0) =>
    client.get(
      "/jobs" +
        buildQueryParams({
          city: city.code,
          position: position.id,
          category: category.id,
          page,
        })
    );

  useDeepCompareEffect(() => {
    setLoading(true);

    getJobs().then(({ data }) => {
      setDonePaginating(false);
      setLoading(false);
      setJobs(data);
    });
  }, [city, position, category]);

  return (
    <>
      <ModalFilter
        pageName="Jobs"
        hideModal={displayModal}
        items={modalItems}
        open={showFilterModal}
        showMap={showMap}
        setShowMap={setShowMap}
      ></ModalFilter>

      <div className="event-list" ref={listRef}>
        <div className="event-list__filters">
          {useWindowSize().width < 768 ? (
            <button
              className="button tracking-widest block md:hidden"
              alt="Filter Jobs"
              onClick={displayModal}
            >
              Filter Jobs
            </button>
          ) : (
            <>
              <Select
                placeholder="City"
                options={props.cities}
                onChange={(v) => {
                  setCity(v);
                }}
              />
              <Select
                placeholder="Position"
                options={props.positions}
                onChange={(v) => {
                  setPosition(v);
                }}
              />
              <Select
                placeholder="Job Type"
                options={props.categories}
                onChange={(v) => {
                  setCategory(v);
                }}
              />
            </>
          )}
          <div
            className="justify-between hidden font-thin md:flex event-list__toggle_map"
            onClick={() => {
              setShowMap(!showMap);
              if (!listRef && show) return;

              window.scrollTo({
                top: listRef.current.offsetTop - 24,
                behavior: "smooth",
              });
            }}
          >
            <span>Toggle {showMap ? "List" : "Map"}</span>
            <span>
              <img
                style={{ filter: "invert(1)", width: "1rem", marginTop: 3 }}
                src={searchIcon}
              />
            </span>
          </div>
        </div>
        {showMap ? (
          <div className="event-list__map">
            <JobsMap jobs={jobs} showMap={showMap} />
          </div>
        ) : (
          <>
            {!loading && jobs && jobs.length === 0 && (
              <div className="event-list__events">
                <div className="event-list__no-events">No Jobs Found</div>
              </div>
            )}
            {loading && (
              <div className="event-list__events">
                <div className="event-list__no-events">
                  <GridLoader />
                </div>
              </div>
            )}
            {!loading && (
              <InfiniteScroll
                className="event-list__events"
                pageStart={0}
                loadMore={(_page) => {
                  getJobs(_page).then(({ data }) => {
                    setJobs(jobs.concat(data));
                    if (data.length !== 16) setDonePaginating(true);
                  });
                }}
                hasMore={!donePaginating}
                loader={<GridLoader key={-1} padded={true} />}
                threshold={-200}
              >
                {jobs.map((job) => (
                  <Job key={job.id} event={job} />
                ))}
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default JobsList;
