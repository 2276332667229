import React from "react";

import MapBlue from "../../assets/ui/map-blue.png";

import "./MapToggle.css";

export function MapToggle({ showMap, setShowMap }) {
  return (
    <div className="map-toggle" onClick={() => setShowMap(!showMap)}>
      <img src={MapBlue} />
    </div>
  );
}
