import React, { useState, useEffect } from "react";

import ImageGallery from "./ImageGallery";

import "./MobileEventDetail.css";

import { useSwipeable } from "react-swipeable";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const MobileEventDetail = (props) => {
    const { event, dates, venue, organizer, hours, categories, featured, metadata, titles } = props;
    let [currentSlide, setSlide] = useState(0);

    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setSlide(currentSlide = index);
        }
    };

    let nextSlide = () => {
        setSlide(currentSlide + 1 <= 4 ? currentSlide + 1 : currentSlide = 0);
    };

    let previousSlide = () => {
        setSlide(currentSlide >= 0 ? currentSlide - 1 : 3);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () =>
            nextSlide(),
        onSwipedRight: () =>
            previousSlide(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const markdownLink = (item) => {
        const link = item.match(/\(([^)]+)\)/);
        if (link !== null) {
            return <a href={link[1]}>Link</a>
        }
        return item;
    }

    let [slides, setSlides] = useState([[
        <div key="slide1" style={{ minHeight: "45vh" }} className="detail__info__mobile pb-8">
            <div>
                <div className="pb-6">
                    <div className="detail__info__header-small">Dates</div>
                    <div>
                        <div>
                            {dates}
                        </div>
                    </div>
                </div>
                <div className="pb-12">
                    <div className="detail__info__header-small">Location</div>
                    <div>
                        <div>{venue.address}</div>
                        <a className="block mt-1 underline"
                            href={`https://maps.google.com/?q=${venue.name} ${venue.address}}`} target="_blank">
                            View in Maps
                        </a>
                    </div>
                </div>
                <div className="pb-12">
                    <div className="detail__info__header-small no-margin">
                        Hours
                    </div>
                    <div>
                        {/* TODO: Don't forget about the hours rendering - gsub & stuff */}
                        {hours}
                    </div>
                </div>
            </div>
        </div>, <div key="slide2" className="leading-loose detail__info__description pb-8">
            <div className="detail__info__header-small">Description</div>
            <div className="pb-12">
                {event.description}
            </div>
        </div>, <div key="slide3" className="detail__info__mobile event-carousel__details pb-8" >

            <div className="pb-12">
                <div className="detail__info__header-small no-margin">
                    Venue
                </div>
                <div>
                    {venue.name}
                </div>
            </div>
            {
                metadata && Object.keys(metadata).length !== 0 ?
                    Object.keys(metadata).map((item) => (
                        <div key={item} className="pb-12">
                            <div className="detail__info__header-small no-margin">
                                {item}
                            </div>
                            <div>
                                {markdownLink(metadata[item])}
                            </div>
                        </div>
                    )) : null
            }
            <div className="pb-12">
                <div className="detail__info__header-small no-margin">
                    Schedule
                </div>
                <div>
                    N/A
                </div>
            </div>
        </div>
    ]]);

    useEffect(() => {

        if (featured) {
            setSlides([...slides, <div key="slide4" className="detail__info__mobile pb-8">
                <div >
                    <div>
                        <div className="pb-6">
                            <div className="detail__info__header-small">
                                Website
                            </div>
                            <div>
                                <a href={`${organizer.website}`} target="_blank">
                                    Link
                                </a>
                            </div>
                        </div>
                        <div className="pb-6">
                            <div className="detail__info__header-small">
                                Email
                            </div>
                            <div>
                                <a href={`mailto:${organizer.email}`} target="_blank">
                                    {organizer.email}
                                </a>
                            </div>
                        </div>
                        <div className="pb-12">
                            <div className="block detail__info__header-small">Description</div>
                            <div className="leading-loose markdown-body">
                                {organizer.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>]);
        }
    }, [])

    return (
        <div {...handlers}>
            <div className="event-carousel__header">
                <ImageGallery photos={event.photos} />
                <div className="mt-6 px-4">
                    <h1 className="detail__info__header">
                        {currentSlide !== 3 ? event.title : 'About'}
                    </h1>
                    <div className="detail__info__subheader">
                        <a>{organizer.name}</a>
                    </div>
                </div>
            </div>


            <div>
                <Carousel className="event-carousel" animationHandler="slide" swipeable={false} showThumbs={false} showStatus={false} showArrows={false} autoPlay={false} dynamicHeight={true} infiniteLoop={true} selectedItem={currentSlide}
                    onChange={updateCurrentSlide}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const indicatorStyles = {
                            background: '#ffffff',
                            borderColor: '#A8A8A8',
                            borderWidth: 0.5,
                            width: 8,
                            height: 8,
                            display: 'inline-block',
                            margin: '0 8px',
                            borderRadius: '100%'
                        };
                        const styles = isSelected ? { ...indicatorStyles, backgroundColor: '#000000', borderColor: '#000000' } : { ...indicatorStyles }
                        if (isSelected) {
                            return (
                                <li
                                    style={styles}
                                />
                            );
                        }
                        return (
                            <li
                                style={styles}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                            />
                        );
                    }}
                >
                    {slides.map((slide) => slide)}
                </Carousel>
            </div>

            <div className="text-center event-carousel__navigation">
                <div className="event-carousel__navigation__content">
                    {/* <DotIndicator amount={slidesCount} current={currentSlide} /> */}
                    <div>
                        {titles[currentSlide]}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileEventDetail;