import './Input.css';

import React, { useState } from "react";

const Input = (props) => {
  const [value, setValue] = useState(props.value || '');

  return <input
    {...props}
    type="text"
    className={"input " + (props.className ? props.className : "")}
    value={props.value}
    onChange={props.onChange}
    placeholder={props.placeholder}
  />
}

export default Input;