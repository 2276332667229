import React, { useMemo, useState, useEffect, useRef } from "react";
import clsx from 'clsx';

import OutsideClickHandler from 'react-outside-click-handler';

import ParagraphInput from "./ParagraphInput";

import useDimensions from "react-cool-dimensions";

import "./Select.css";

const SelectHero = ({
  className,
  onChange,
  onMouseEnter,
  options = [],
  selector = (v) => v.name,
  ...props
}) => {
  //TODO: Still working on the width thingy - this is probably not the best solution but it should work.

  const { observe, unobserve, width, height, entry } = useDimensions({
  });

  const [open, setOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [selected, setSelected] = useState(null);
  const [value, setValue] = useState(props.value || "");

  const matches = useMemo(
    () =>
      value === ""
        ? options
        : options.filter((v) =>
          selector(v).toLowerCase().includes(value.toLowerCase())
        ),
    [value, options]
  );

  const handleChange = (e) => {
    e.preventDefault();
    setSelected(null);

    if (e.target.value.trim().length === 0) setSelected(null);

    setValue(e.target.value);
  };

  const handleSelection = (option) => () => {
    setSelected(option);
    setValue(selector(option));
    if (onChange) onChange(option);
  };

  const handleClose = () => {
    setValue(props.value);
    setOpen(false);
    setReadOnly(true);
  };

  const handleModalOpen = () => {
    setOpen(!open)
    setReadOnly(!readOnly)
    if (!open) {
      setValue("")
    }
    else {
      setValue(props.value)
    }
  }

  const handleEscapeClear = (e) => {
    if (e.key === "Escape") {
      setValue(props.value)
    }
  };

  // useEffect(() => {
  //   setWidth(span.current.offsetWidth);
  // }, [value]);

  return (
    <OutsideClickHandler
      onOutsideClick={handleClose}
      display="inline"
    >
      <p className="hide-span" ref={observe}>{value}</p>
      <span onClick={handleModalOpen} onMouseEnter={onMouseEnter} className={clsx("", className)}>
        <ParagraphInput
          {...props}
          style={{ width: width }}
          value={value}
          readOnly={readOnly}
          onChange={handleChange}
          onKeyDown={handleEscapeClear}
        />
      </span>.

      <div
        className="select"
      >
        <div style={{
          borderBottom: matches.length <= 0 ? "none" : "1px solid #ddd"
          , backgroundColor: "#faf9f7"
        }} className={"select__dropdown " + (open ? "open" : "")}>
          {(selected ? options : matches).map((option, i) => (
            <div
              key={i}
              className="select__item"
              onClick={handleSelection(option)}
            >
              {selector(option)}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default SelectHero;
