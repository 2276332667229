import "./EventList.css";

import React, { useState, useEffect, useRef } from "react";

// import JobsMap from "./JobsMap";
import Select from "../ui/Select";
import { buildQueryParams } from "../helpers/query";
import client from "../api/client";
import searchIcon from "../assets/ui/search-white.png";
import useDeepCompareEffect from "use-deep-compare-effect";
import InfiniteScroll from "react-infinite-scroller";

import News from "./News";
import GridLoader from "./GridLoader";

const NewsList = (props) => {
  const [news, setNews] = useState(props.news);

  const [city, setCity] = useState({});
  const [date, setDate] = useState({});
  const [category, setCategory] = useState({});
  const [showMap, setShowMap] = useState(props.showMap || false);
  const [loading, setLoading] = useState(true);
  const [donePaginating, setDonePaginating] = useState(false);
  const listRef = useRef();

  const getNews = (page = 0) =>
    client.get(
      "/news" +
        buildQueryParams({
          city: city.code,
          ends: date.value,
          category: category.id,
          page,
        })
    );

  useDeepCompareEffect(() => {
    setLoading(true);

    getNews().then(({ data }) => {
      setDonePaginating(false);
      setNews(data);
      setLoading(false);
    });
  }, [city, date, category]);

  return (
    <div className="event-list" ref={listRef}>
      <div className="text-lg block md:hidden">Latest News</div>
      {/* <div className="event-list__filters">
        <Select
          placeholder="City"
          options={props.cities}
          onChange={(v) => {
            setCity(v);
          }}
        />
        <Select
          placeholder="Position"
          options={props.dates}
          onChange={(v) => {
            setDate(v);
          }}
        />
        <Select
          placeholder="Job Type"
          options={props.categories}
          onChange={(v) => {
            setCategory(v);
          }}
        />
        <div
          className="flex justify-between font-thin event-list__toggle_map"
          onClick={() => {
            setShowMap(!showMap);
            if (!listRef && show) return;

            window.scrollTo({
              top: listRef.current.offsetTop - 24,
              behavior: "smooth",
            });
          }}
        >
          <span>Toggle {showMap ? "List" : "Map"}</span>
          <span>
            <img
              style={{ filter: "invert(1)", width: "1rem", marginTop: 3 }}
              src={searchIcon}
            />
          </span>
        </div>
      </div> */}
      {/* {showMap ? (
        <div className="event-list__map">
          <JobsMap news={news} showMap={showMap} />
        </div>
      ) : ( */}
      <>
        {!loading && news && news.length === 0 && (
          <div className="event-list__events">
            <div className="event-list__no-events">No News Found</div>
          </div>
        )}
        {loading && (
          <div className="event-list__events">
            <div className="event-list__no-events">
              <GridLoader />
            </div>
          </div>
        )}
        {!loading && (
          <InfiniteScroll
            className="event-list__events"
            pageStart={0}
            loadMore={(_page) => {
              getNews(_page).then(({ data }) => {
                setNews(news.concat(data));
                if (data.length !== 16) setDonePaginating(true);
              });
            }}
            hasMore={!donePaginating}
            loader={<GridLoader key={-1} padded={true} />}
            threshold={-200}
          >
            {news.map((newsPost) => (
              <News key={newsPost.id} event={newsPost} />
            ))}
          </InfiniteScroll>
        )}
      </>
      {/* )} */}
    </div>
  );
};

export default NewsList;
