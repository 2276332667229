import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import AnimatedNumber from "animated-number-react";
import * as Yup from "yup";
import TextareaAutosize from "react-textarea-autosize";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

import Event from "../Event";
import DateFilter from "../filters/DatesFilter/DateFilter";
import client from "../../api/client";
import { getCSRFToken } from "../../helpers";

import PreviewImage from "../../assets/images/tdr-submission-placeholder.jpg";
import "./SubmissionForm.css";

const FormContext = createContext();

const RECAPTCHA_SITE_KEY = "6LedeSsgAAAAAGedyvNkwXYmQYadb7y_qlnA6xZp";

const TEST_VALUES = {
  title: "The Best Event",
  description: "Yoted",
  venue: "The Place",
  city: "New York",
  address: "123 Place St. NYC",
  website_link: "https://me.com",
  contact_name: "Me",
  contact_email: "me@me.com",
  image_link: "https://thelink.com/image.jpg",
  recaptcha_token: "",
  dates: {
    from: {
      day: 12,
      month: 6,
      year: 2022,
    },
    to: {
      day: 19,
      month: 8,
      year: 2022,
    },
  },
  hours: "M-F: 12-9pm",
};

const INITIAL_VALUES = {
  title: "",
  description: "",
  venue: "",
  city: "",
  address: "",
  image_link: "",
  website_link: "",
  contact_name: "",
  contact_email: "",
};

const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().trim(),
  description: Yup.string().trim(),
  venue: Yup.string().trim(),
  city: Yup.string().trim(),
  address: Yup.string().trim(),
  website_link: Yup.string().trim(),
  contact_name: Yup.string().trim(),
  contact_email: Yup.string().trim(),
  recaptcha_token: Yup.string().trim(),
});

const FormStepper = ({ children, ...props }) => {
  const stepElements = React.Children.toArray(children);
  const { totalSteps, currentStep, setCurrentStep } = useContext(FormContext);

  return (
    <Form autoComplete="off" className={props.className}>
      {stepElements[currentStep]}
      <div className="mt-8">
        {currentStep > 0 ? (
          <div
            className="inline-block mr-8 text-2xl cursor-pointer button"
            style={{ padding: "1rem 4rem" }}
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Prev
          </div>
        ) : null}
        <button
          className="inline-block text-2xl cursor-pointer button"
          style={{ padding: "1rem 4rem" }}
          type="submit"
        >
          {currentStep < totalSteps - 1 ? "Next" : "Submit"}
        </button>
      </div>
    </Form>
  );
};

const Progress = ({ ...props }) => {
  const { currentStep, totalSteps } = useContext(FormContext);

  const percentFinished = (currentStep / totalSteps) * 100;
  const percentFormat = (value) => Math.floor(value) + "%";

  return (
    <div {...props}>
      You currently have{" "}
      <AnimatedNumber
        duration={300}
        value={percentFinished}
        formatValue={percentFormat}
      />{" "}
      of the details completed.
    </div>
  );
};

const Preview = ({ defaultEvent, ...props }) => {
  const event = { venue: { city: {} }, organizer: {} };
  const { values: formValues } = useFormikContext();

  event.title = formValues.title || defaultEvent.title;
  if (formValues.dates && formValues.dates.from && formValues.dates.to) {
    // Fix the month offset for the date picker
    event.starts = {
      ...formValues.dates.from,
      month: formValues.dates.from.month - 1,
    };
    event.ends = {
      ...formValues.dates.to,
      month: formValues.dates.to.month - 1,
    };
  }
  event.organizer.name = formValues.venue || defaultEvent.organizer.name;
  event.venue.city.name = formValues.city || defaultEvent.venue.city.name;

  // Inject the preview image for now
  // TODO: (SeedyROM) Replace me
  defaultEvent.photos[0].url = PreviewImage;

  return <Event event={{ ...defaultEvent, ...event }} {...props} noLink />;
};

const InputForm = ({ ...props }) => {
  return (
    <FormStepper {...props}>
      {/* <div className="flex flex-col justify-between h-full"> */}
      <div className="form__section">
        <div className="form__intro">
          Submit your event for review and reach industry professionals, media,
          buyers and artists alike. Welcome to The Design Release.
        </div>
        <div className="form__section__field">
          <div className="form__section__header">
            What is the name of your Event?
          </div>
          <Field
            name="title"
            type="text"
            className="w-full input"
            required={true}
            placeholder="Type your answer here."
          />
        </div>
      </div>
      <div className="form__section">
        <div className="form__section__header">Tell us about your event?</div>
        <div className="form__section__field">
          <div className="form__section__label">Description:</div>
          <Field name="description">
            {({ field: { value }, form: { setFieldValue } }) => (
              <TextareaAutosize
                className="w-full input"
                required={true}
                placeholder="What's this event about, who's involved, etc..."
                value={value}
                onChange={({ target: { value } }) =>
                  setFieldValue("description", value)
                }
              />
            )}
          </Field>
        </div>
      </div>
      <div className="form__section">
        <div className="form__section__header">Where is it happening?</div>
        <div className="form__section__field">
          <div className="form__section__label">City:</div>
          <Field
            name="city"
            type="text"
            className="w-full input"
            required={true}
            placeholder="Where is this?"
          />
        </div>

        <div className="form__section__field">
          <div className="form__section__label">Name of Venue/Host:</div>
          <Field
            name="venue"
            type="text"
            className="w-full input"
            required={true}
            placeholder="What's the name of the host/venue?"
          />
        </div>

        <div className="form__section__field">
          <div className="form__section__label">Address: </div>
          <Field
            name="address"
            type="text"
            className="w-full input"
            required={true}
            placeholder="What's the full address?"
          />
        </div>
      </div>
      <div className="form__section">
        <div className="form__section__header">When is it happening?</div>
        <div className="form__section__field">
          <div className="form__section__label">Dates:</div>
          <Field name="dates">
            {({ field: { value }, form: { setFieldValue } }) => (
              <DateFilter
                onChange={(value) => setFieldValue("dates", value)}
                value={value}
                placeholder="What dates are you open?"
              />
            )}
          </Field>
        </div>
        <div className="form__section__field">
          <div className="form__section__label">Hours: </div>
          <Field name="hours">
            {({ field: { value }, form: { setFieldValue } }) => (
              <TextareaAutosize
                className="w-full input"
                required={true}
                placeholder="What hours during the week are you open?"
                value={value}
                onChange={({ target: { value } }) =>
                  setFieldValue("hours", value)
                }
              />
            )}
          </Field>
        </div>
      </div>
      <div className="form__section">
        <div className="form__section__header">Link to your images</div>
        <div className="form__section__field">
          <div className="form__section__label">Image: </div>
          <Field
            name="image_link"
            type="url"
            className="w-full input"
            placeholder="Dropbox, WeTransfer or Google Drive"
          />
          <div className="mt-8 text-sm italic opacity-75">
            Image requirements: Please send a landscape image with a minimum of
            1500px wide, we do not accept fliers or images with people.
          </div>
        </div>
      </div>
      <div className="form__section">
        <div className="form__section__header">Who Are You?</div>
        <div className="form__section__field">
          <div className="form__section__label">Your Name: </div>
          <Field
            name="contact_name"
            type="text"
            className="w-full input"
            required={true}
            placeholder="Who are you?"
          />
        </div>
        <div className="form__section__field">
          <div className="form__section__label">Your E-mail: </div>
          <Field
            name="contact_email"
            type="email"
            className="w-full input"
            required={true}
            placeholder="Where can we contact you?"
          />
        </div>
        <div className="form__section__field">
          <div className="form__section__label">Your Online Presence:</div>
          <Field
            name="website_link"
            type="url"
            className="w-full input"
            required={true}
            placeholder="A link to your social media or website: https://mywebsite.com"
          />
        </div>
        <div className="form__section__field">
          {/* TODO: (SeedyROM) How to show this dynamically? */}
          {/* <div className="form__section__label">Confirm Your Humanity:</div> */}
          <Field name="recaptcha_token">
            {({ form: { setFieldValue } }) => (
              <div>
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                  <GoogleReCaptcha
                    onVerify={(token) =>
                      setFieldValue("recaptcha_token", token)
                    }
                  />
                </GoogleReCaptchaProvider>
              </div>
            )}
          </Field>
        </div>
      </div>
    </FormStepper>
  );
};

const SubmissionForm = ({ defaultEvent }) => {
  const [step, setStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(6);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

  const contextValue = useMemo(
    () => ({
      currentStep: step,
      setCurrentStep: setStep,
      totalSteps,
      setTotalSteps,
    }),
    [step, totalSteps]
  );

  return (
    <FormContext.Provider value={contextValue}>
      <Formik
        initialValues={INITIAL_VALUES}
        // initialValues={TEST_VALUES}
        // validationSchema={VALIDATION_SCHEMA}
        className="h-full"
        onSubmit={async (values) => {
          if (step === totalSteps - 1) {
            await client.post("/submit/event", values, {
              headers: { "X-CSRF-TOKEN": getCSRFToken() },
            });
            setSuccessfullySubmitted(true);
            setStep(totalSteps);
          } else {
            setStep(step + 1);
          }
        }}
      >
        {/* {({ errors, values }) => ( */}
        <div className="block grid-cols-2 gap-12 py-8 lg:grid-cols-4 lg:grid md:py-8 lg:py-16">
          <div>
            <div className="mb-12 md:mb-20">
              <Progress className="w-3/4" />
            </div>
            <div>
              <div className="mb-8 md:mb-12">Card Preview:</div>
              <Preview defaultEvent={defaultEvent} />
            </div>
          </div>
          <div className="col-start-3 col-end-5">
            {successfullySubmitted ? (
              <div>
                <div className="mb-12 text-3xl">Successfully submitted!</div>
                <div className="text-lg">
                  <p className="mb-8">
                    If you're submission is approved we'll be in contact with
                    you shortly!
                  </p>
                  <p className="italic">
                    For other info or to check in on the status of your
                    submission please send an e-mail to{" "}
                    <a
                      href="mailto:info@thedesignrelease.com"
                      className="underline"
                    >
                      info@thedesignrelease.com
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              <InputForm />
            )}
          </div>
        </div>
        {/* )} */}
      </Formik>
    </FormContext.Provider>
  );
};

export default SubmissionForm;
