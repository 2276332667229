import EventHelper from "../helpers/event";
import React from "react";
import StickyBox from "react-sticky-box";

const DetailSidebar = ({ event, venue, organizer }) => {
  return (
    <StickyBox offsetBottom={40} offsetTop={175}>
      <div className="detail__info__info sticky-sidebar">
        <div>
          <div className="mb-2 text-base leading-normal detail__title">{event.title}</div>
        </div>
        <div>
          <div>{organizer.name}</div>
        </div>
        <div>
          <div className="my-4 detail__info__header-small">Dates</div>
          <div>
            <div>{EventHelper.when(event)}</div>
            {/* <a
              className="block mt-2 underline"
              href={EventHelper.buildCalendarUrl(event, venue)}
              target="_blank"
            >
              Add To Calendar
            </a> */}
          </div>
        </div>
        <div>
          <div className="detail__info__header-small">Location</div>
          <div>
            <div>{venue.address}</div>
            <a
              className="block mt-4 underline"
              href={`https://maps.google.com/?q=${venue.name} ${venue.address}`}
              target="_blank"
            >
              View in Maps
            </a>
          </div>
        </div>
        <div>
          <div className="detail__info__header-small">Venue</div>
          <div>
            <div>{venue.name}</div>
          </div>
        </div>
      </div>
    </StickyBox>
  );
};

export default DetailSidebar;
