import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import './ModalFilter.css';

import { DimensionsFilter, CategoryFilter } from "../";
import { Select, AutoSelect } from "../../../ui";

import Toggle from "../../../ui/Toggle"

import PlusIcon from "../../../assets/ui/plus.svg";
import MinusIcon from '../../../assets/ui/minus.svg';

import { WorksFilterModal } from "../../../components/WorksList";



const ModalFilter = (props) => {
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {props.open && (
          <motion.div
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
          >
            <div className={`mobile-modal ${props.pageName.toLowerCase() == 'works' ? "overflow-scroll" : ""}`}>
              <div className="mobile-modal__items">
                <div className="mobile-modal__header">
                  <div className="mobile-modal__header__title">Filter {props.pageName}</div>
                  <div className="mobile-modal__header__close" onClick={props.hideModal}>Cancel</div>
                </div>
                <div>
                  {props.items.map((item) => item.type == 'select' ? <Select
                    key={item.placeholder}
                    className="mobile-modal__select"
                    placeholder={item.placeholder}
                    options={item.options}
                    onChange={(v) => {
                      item.method(v);
                    }}
                    value={item.value.name}
                  /> :
                    <></>
                    //<Toggle value={item.value} placeholder={item.placeholder} />
                  )}

                  {props.children}

                  {(props.extraItems && props.extraItems.length != 0) && (
                    <div
                      className="flex justify-between select mobile-modal__select event-list__toggle_map"
                      onClick={props.toggleMoreFilters}
                    >
                      <span>More Filters</span>
                      <span>
                        <img style={{ filter: 'invert(1)' }} src={props.filtersOpen ? MinusIcon : PlusIcon} alt="" />
                      </span>
                    </div>
                  )}


                  {
                    props.filtersOpen ? <>
                      {props.extraItems.map((item) => item.type == 'select' ? <Select
                        key={item.placeholder}
                        className="mobile-modal__select"
                        placeholder={item.placeholder}
                        options={item.options}
                        onChange={(v) => {
                          item.method(v);
                        }}
                        value={item.value.name}
                      /> :

                        // TODO: Return it at some point. 
                        /* item.type == 'dimensions-filter' ?
                            <><DimensionsFilter
                           key={item.placeholder}
                           className={"collecting-page-filter"}
                           onChange={(v) => {
                             item.method(v);
                           }}
                         />
                           <div style={{ gridArea: "span 4 / span 4" }}>
                             <WorksFilterModal.Target key={item.placeholder} style={{ marginTop: "-1px" }} />
                           </div></> */
                        item.type == 'auto-select' ? <AutoSelect
                          key={item.placeholder}
                          className={"collecting-page-filter"}
                          selectorId={(v) => v.id}
                          placeholder="Material"
                          options={item.options}
                          onChange={(v) => {
                            item.method(v.filter((x) => x.checked === true).map((x) => x.id));
                          }}
                        /> : <></>)}
                    </> : <></>
                  }
                  <button className="block tracking-widest button"
                    alt="Apply Filters"
                    onClick={props.hideModal}
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default ModalFilter;
