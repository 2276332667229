import "./Event.css";
import "./News.css";

import EventHelper from "../helpers/event";
import React from "react";
import moment from "moment";

import { useWindowSize } from "../utils";
import LineClamp from "react-line-overflow";

export const descriptionReplacePattern = /(<([^>]+)>|\[([^>]+)\]|&([^>]+);)/gi;

const EditorialPost = (props) => {
  const { event, url, onMouseOver, eventRef } = props;

  const windowSize = useWindowSize();

  return (
    <a
      href={"/in-sites/" + event.slug}
      className={"event " + props.className}
      onMouseOver={onMouseOver}
      ref={eventRef}
    >
      <div
        className="event__photo xs:mt-12"
        style={{
          "--aspect-ratio": "4/3",
          backgroundImage: `url(${event.photos[0].url})`,
        }}
      />
      {windowSize.width < 768 && url === "/" ? (
        <div>
          <div className="my-6 w-5/6 text-lg event__title">
            <LineClamp lines={2} ellipsis="...">
              {event.title} by {event.editorial_author.name}
            </LineClamp>
          </div>

          <div className="mt-4">
            <div className="mt-2 mb-2 text-xs font-light">DESCRIPTION</div>
            <div className="text-sm event_subtitle">
              <LineClamp lines={3} ellipsis="...">
                {event.excerpt.replace(descriptionReplacePattern, "")}
              </LineClamp>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-6 mb-1 text-xs font-light event__info">
            <div>
              {event.categories
                .split(",")
                .map((v) => v && v[0].toUpperCase() + v.slice(1))
                .join(", ")}
            </div>
            <div>{event.editorial_author.name}</div>
          </div>
          <div className="mb-6 font-thin event__info">
            <div>{EventHelper.daysAgo(moment(event.created_at))}</div>
            <div>{event.editorial_author.role}</div>
          </div>
          <div className="my-6 w-3/4 text-sm event__title">
            <LineClamp lines={2} ellipsis="...">
              {event.title}
            </LineClamp>
          </div>

          <div className="text-sm font-thin event_subtitle">
            <LineClamp lines={3} ellipsis="...">
              {event.excerpt.replace(descriptionReplacePattern, "")}
            </LineClamp>
          </div>
        </div>
      )}
    </a>
  );
};

export default EditorialPost;
