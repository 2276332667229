import React, { useState, useMemo, useCallback, useEffect, useRef, useContext } from 'react';

import useDeepCompareEffect from "use-deep-compare-effect";
import groupBy from "lodash/groupBy";

import "./CategoryFilter.css";
import ModalDropDown from "../../../ui/ModalDropDown";

const CategoryFilter = (props) => {

  const [category, setCategory] = useState({});

  const hasMore = useMemo(() => Object.keys(props.categories).length > 4, [
    props.categories
  ]);

  const topLevelCategories = useMemo(
    () => props.categories.filter((x) => x.level === 0),
    [props.categories]
  );
  const secondLevelCategories = useMemo(
    () => props.categories.filter((x) => x.level === 1),
    [props.categories]
  );

  const selectableCategories = useMemo(
    () =>
      groupBy(
        secondLevelCategories,
        (x) => topLevelCategories.filter((y) => y.id === x.parent_id)[0].name
      ),
    [topLevelCategories, secondLevelCategories]
  );

  useDeepCompareEffect(() => {
    if (props.category) setCategory(props.category);
  }, [props.category || {}]);

  useDeepCompareEffect(() => {
    if (props.onChange || category) props.onChange(category);
  }, [props.onChange, category]);


  return (
    <div className={props.className + " " + "category-filter"}>
      <ModalDropDown
        placeholder="Category"
        value={category.name}
        name="categories"
      >
        <div className="block grid-cols-4 gap-8 md:grid event-list__modal category-filter__view">
          {Object.keys(selectableCategories).map((rootCategory) => {
            const root = topLevelCategories.filter((x) => x.name === rootCategory)[0];

            return (
              <div key={rootCategory}>
                <div
                  className={"category-filter__root_category " + + (category.id === root.id ? "active" : "")}
                  onClick={() => {
                    if (root.id !== category.id) {
                      setCategory(root)
                    } else {
                      setCategory({});
                    }
                  }}
                >
                  {rootCategory}
                </div>
                <div>
                  {selectableCategories[rootCategory].map((_category) => (
                    <div
                      className={"category-filter__subcategory " + (_category.id === category.id ? "active" : "")}
                      key={_category.id}
                      onClick={() => {
                        if (_category.id !== category.id) {
                          setCategory(_category);
                        } else {
                          setCategory({});
                        }
                      }}
                    >
                      {_category.name}
                    </div>
                  ))}
                  {/*<div*/}
                  {/*  onClick={() => {*/}
                  {/*    if (selectableCategories[rootCategory][0].parent_id !== category.id) {*/}
                  {/*      setCategory(*/}
                  {/*        topLevelCategories.filter(*/}
                  {/*          (x) =>*/}
                  {/*            x.id ===*/}
                  {/*            selectableCategories[rootCategory][0].parent_id*/}
                  {/*        )[0]*/}
                  {/*      )*/}
                  {/*    } else {*/}
                  {/*      setCategory({});*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*  style={{*/}
                  {/*    backgroundColor:*/}
                  {/*      category.id ===*/}
                  {/*      selectableCategories[rootCategory][0].parent_id*/}
                  {/*        ? "#000"*/}
                  {/*        : "transparent",*/}
                  {/*    color:*/}
                  {/*      category.id ===*/}
                  {/*      selectableCategories[rootCategory][0].parent_id*/}
                  {/*        ? "#fff"*/}
                  {/*        : "inherit",*/}
                  {/*    cursor: "pointer"*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  All {rootCategory}*/}
                  {/*</div>*/}
                </div>
              </div>
            );
          })}
          {/*{props.canHaveMore && hasMore && (*/}
          {/*  <div style={{marginTop: 10, cursor: "pointer"}}>*/}
          {/*    + Show more categories*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </ModalDropDown>
    </div>
  )
};

export default CategoryFilter;
