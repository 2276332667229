import { create } from "zustand";

export const useEventsStore = create((set) => ({
  city: {},
  setCity: (city) => set({ city }),
  date: {},
  setDate: (date) => set({ date }),
  category: {},
  setCategory: (category) => set({ category }),
  showMap: false,
  setShowMap: (showMap) => set({ showMap }),
}));
