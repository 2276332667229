import React, { useCallback, useState, useEffect, useMemo } from "react";
import { motion, useAnimation } from "framer-motion";

import "./Featured.css";

const isButtonEnabled = (events) => {
  return events.length > 1 ? "button" : "button button--disabled";
};

const FeaturedEvents = ({ events, defaultCurrentEvent, nextInterval }) => {
  const [currentEvent, setCurrentEvent] = useState(defaultCurrentEvent);
  const controls = useAnimation();

  const next = useCallback(async () => {
    await controls.start({ opacity: 0, x: 20, });

    setCurrentEvent((v) => (v + 1) % events.length);
    controls.set({ x: 0 });

    await controls.start({ opacity: 1, x: 0, });
  }, [controls, setCurrentEvent, events]);

  const previous = useCallback(async () => {
    await controls.start({ opacity: 0, x: -20, });

    if (currentEvent - 1 < 0) {
      setCurrentEvent(() => events.length - 1);
    } else {
      setCurrentEvent((v) => v - 1);
    }

    controls.set({ x: 0 });

    await controls.start({ opacity: 1, });
  }, [controls, currentEvent, setCurrentEvent, events]);

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, nextInterval);

    return () => clearInterval(interval);
  }, []);

  const event = useMemo(() => events[currentEvent], [events, currentEvent]);

  return (
    <motion.div
      animate={controls}
      transition={{ ease: "easeOut", duration: 0.3 }}
    >
      <div className="featured">
        <div className="flex flex-col featured__left">
          <div className="featured__info">
            <div>
              <a href={"/source/" + event.slug}>
                <h2>{event.name}</h2>
                <h3>by {event.designer.name}</h3>
              </a>
            </div>
            <div>
              <div className="featured__when">
                <div className="featured__header">
                  categories
                </div>
                <div>
                  {event.category ? event.category.name : "N/A"}
                </div>
                <div className="featured__header">
                  designer
                </div>
                <div>
                  {event.designer.name}
                </div>
              </div>
              <div className="block md:hidden">
                <div className="featured__header">Description</div>
                <p className="mb-4 text-sm featured__description">
                  {event.description.slice(0, 350)}...
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-12 mt-8 featured__navigation lg:block">
            <button className={isButtonEnabled(events)} onClick={previous}>
              Previous
            </button>
            <button className={isButtonEnabled(events) + " block lg:hidden"} onClick={next}>
              <div>Next</div>
              <div>
                <div className="button__timer"></div>
              </div>
            </button>
          </div>
        </div>

        <div className="flex flex-col justify-between">
          <p className="hidden featured__description md:block">
            {event.description.slice(0, 350)}...
          </p>
          <button className={isButtonEnabled(events) + " hidden lg:block"} onClick={next}>
            <div>Next</div>
            <div>
              <div className="button__timer"></div>
            </div>
          </button>
        </div>

        <div className="featured__right">
          <div className="featured__photo">
            <a href={"/work/" + event.slug}>
              {event.photos.length && (
                <img src={event.photos[0].url} alt={event.title} />
              )}
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

FeaturedEvents.defaultProps = {
  defaultCurrentEvent: 0,
  nextInterval: 15000,
};

export default FeaturedEvents;
