import EventHelper from "../helpers/event";
import React from "react";
import StickyBox from "react-sticky-box";
import moment from "moment";

const JobDetailSidebar = ({ event, organizer, city }) => {
  return (
    <StickyBox offsetBottom={40} offsetTop={175}>
      <div className="detail__info__info sticky-sidebar">
        <div>
          <div className="mb-2 text-base leading-normal detail__title">{event.title}</div>
        </div>
        <div>
          <div>{organizer.name}</div>
        </div>
        <div>
          <div className="my-4 detail__info__header-small">Posted</div>
          <div>
            <div>{moment(event.created_at).format("YYYY-MM-DD")}</div>
            {/* <a
              className="block mt-2 underline"
              href={EventHelper.buildCalendarUrl(event, venue)}
              target="_blank"
            >
              Add To Calendar
            </a> */}
          </div>
        </div>
        <div>
          <div className="detail__info__header-small">Location</div>
          <div>
            <div>{city.name}</div>
            <a
              className="block mt-4 underline"
              href={`https://maps.google.com/?q=${city.name}`}
              target="_blank"
            >
              View in Maps
            </a>
          </div>
        </div>
        <div>
          <div className="detail__info__header-small">Host Company</div>
          <div>
            <div>{organizer.name}</div>
          </div>
        </div>
      </div>
    </StickyBox>
  );
};

export default JobDetailSidebar;
