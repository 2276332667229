import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Calendar } from "react-modern-calendar-datepicker";
import OutsideClickHandler from "react-outside-click-handler";

import "../../../ui/Select.css";
import "./DateFilter.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import chevronDown from "../../../assets/ui/Chevron_Down.svg";
import chevronDownLargeScreen from "../../../assets/ui/Arrow_White.png";
import closeIcon from "../../../assets/ui/Close_Dark.svg";
import { useWindowSize } from "../../../utils";
import EventHelper from "../../../helpers/event";

const DateFilter = ({
  className,
  onChange,
  options = [],
  selector = (v) => v.name,
  customOption,
  ...props
}) => {
  const [open, setOpen] = useState(true);

  const windowSize = useWindowSize();

  const [selectedDayRange, setSelectedDayRange] = useState(
    props.value || {
      from: null,
      to: null,
    }
  );

  useEffect(() => {
    onChange(selectedDayRange);
  }, [selectedDayRange.from, selectedDayRange.to]);

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedDayRange({
      from: null,
      to: null,
    });
    setOpen(false);
  };

  const handleEscapeClear = (e) => {
    if (e.key === "Escape") {
      setSelectedDayRange({
        from: null,
        to: null,
      });
      setOpen(false);
    }
  };

  const fromCalendarDate = (date) => {
    return `${date.year}-${date.month}-${date.day}`;
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={clsx("select", className)} onClick={() => setOpen(!open)}>
        <div className="py-6 select-selected">
          {selectedDayRange.to && selectedDayRange.from ? (
            EventHelper.when({
              starts: fromCalendarDate(selectedDayRange.from),
              ends: fromCalendarDate(selectedDayRange.to),
            })
          ) : (
            <div className="opacity-75">{props.placeholder}</div>
          )}
          <div
            className={clsx("select__indicator", {
              close: open || (selectedDayRange.to && selectedDayRange.from),
              "select__indicator--custom": customOption,
            })}
          >
            {open && (
              <img
                src={closeIcon}
                alt="close"
                onClick={handleClear}
                className="close"
              />
            )}
            {!open && (
              <img
                src={
                  windowSize.width < 768 ? chevronDown : chevronDownLargeScreen
                }
                alt={open ? "open" : "closed"}
                className={open ? "open" : ""}
              />
            )}
          </div>
        </div>
        <div
          className={
            "select__dropdown select__dropdown-calendar " + (open ? "open" : "")
          }
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        >
          <Calendar
            value={selectedDayRange}
            onChange={setSelectedDayRange}
            colorPrimary={"#fff"}
            colorPrimaryLight={"#fff"}
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default DateFilter;
