import React from "react";

import './PlatformDropDown.css';
import DropDown from "../ui/DropDown";

const PlatformDropDown = () => {
  let selected = null;

  if (location.href.endsWith('/events')) {
    selected = { key: "Events", value: "/events" };
  } else if (location.href.endsWith('/jobs')) {
    selected = { key: "Jobs", value: "/jobs" };
  } else if (location.href.endsWith('/news')) {
    selected = { key: "News", value: "/news" };
  } else if (location.href.endsWith('/works')) {
    selected = { key: "Works", value: "/works" };
  }

  return (
    <div className="sm:hidden lg:block platform-dropdown">
      <DropDown
        placeholder="Choose Platform"
        items={[
          { key: "Events", value: "/events" },
          // { key: "Jobs", value: "/jobs" },
          { key: "Works", value: "/works" },
          { key: "News", value: "/news" },
        ]}
        keySelector={(x) => x.key}
        onChange={(value) => {
          location.href = value
        }}
        selected={selected}
      />
    </div>
  )
};

export default PlatformDropDown;
