import "./ImageGallery.css";

import { Carousel } from "react-responsive-carousel";
import React from "react";

const ImageGallery = ({ photos }) => {
  return (
    <Carousel
      autoPlay={true}
      thumbWidth={80}
      centerMode={true}
      renderThumbs={() => null}
      renderIndicator={() => null}
      className="image-gallery"
    >
      {photos.map((photo) => (
        <div key={photo.url}>
          <img src={photo.url} alt={photo.url} />
        </div>
      ))}
    </Carousel>
  );
};

export default ImageGallery;
