import React from "react";
import EventHelper from "../helpers/event";
import moment from "moment";


const FeaturedEditorialPostInline = ({post, ...props}) => {
  return (
    <div className="lg:col-span-4 lg:grid gap-12 mb-16 grid-cols-2 lg:mb-24" {...props}>
      <img alt={post.title} src={post.photos[0].url} className="w-full"/>
      <div className="flex flex-col justify-between">
        <div className="text-xl sm:text-2xl lg:text-4xl tracking-wide lg:w-3/4 font-mono">{post.title}</div>
        <div className="lg:grid lg:grid-cols-2 gap-12">
          <a className="button dark" style={{padding: "1rem"}} href={`/in-sites/${post.slug}`}>
            Read More
          </a>
          <div className="flex flex-col justify-end align-end">
            <div className="inline-block ml-auto text-xs">Written by <span
              className="font-medium">{post.editorial_author.name}</span>
            </div>
            <div className="inline-block ml-auto text-xs">Published <span
              className="font-medium">{EventHelper.daysAgo(moment(post.created_at))}</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedEditorialPostInline;