import React, { useState, useCallback, useMemo, createContext, useContext } from "react";
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'clsx';

import './DropDown.css';
import { accessor, identity } from "../helpers";
import chevronDownMobile from '../assets/ui/Chevron_Down.svg';
import { WorksFilterModal } from "../components/WorksList";

import chevronDown from "../assets/ui/Arrow_White.png"
import { useWindowSize } from "../utils";

const KEY_SELECTOR_KEY = 'key';
const VALUE_SELECTOR_KEY = 'value';

const ModalDropDown = ({ selected = false, ...props }) => {
  const onChange = useMemo(() => props.onChange || identity, [props.onChange]);
  const value = useMemo(() => props.value, [props.value]);

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  const handleItemClick = useCallback((e, item) => {
    e.stopPropagation();

    onChange(valueSelector(item));
    setOpen(false);
  }, [onChange]);

  const windowSize = useWindowSize();


  const content = useMemo(() => (
    <div className={cn('', { 'open': open, [props.className]: open })}>
      <div className={cn('dropdown-scrollable', props.classNameScroll)}>
        <WorksFilterModal.Source className={props.className}>
          <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
            {props.children}
          </OutsideClickHandler>
        </WorksFilterModal.Source>
        {!props.children && (
          <>
            {props.items.map((item, i) => (
              <div key={i} className="dropdown__item" onClick={(e) => handleItemClick(e, item)}>
                {keySelector(item)}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  ), [props.children, props.className, props.items, open,]);

  return (

    <div className={cn('dropdown select', { 'dropdown--no-items': !props.children }) + ' ' + props.className} tabIndex="0">
      <div className="dropdown__menu" onClick={() => { setOpen(!open) }}>
        <div>{value ? value : props.placeholder}</div>
        <div className={cn('indicator', { 'open-indicator': open })}>
          <img src={windowSize.width < 768 ? chevronDownMobile : chevronDown} />
        </div>
      </div>
      {open && content}
    </div>
  )
}

ModalDropDown.propTypes = {
  onChange: PropTypes.func,
  keySelector: PropTypes.func,
  valueSelector: PropTypes.func,
  items: PropTypes.array,
  selected: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  classNameScroll: PropTypes.string,
};

export default ModalDropDown;
