import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import HamburgerIcon from "../assets/ui/hamburger.png";
import Close from "../assets/ui/Close_Dark.svg";
import Logo from "../assets/images/tdr-symbol.png";

const MobileNavbar = ({ isAuthenticated, isAdmin }) => {
  const [open, setOpen] = useState(false);

  const openNavbar = () => {
    !open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = null);
    setOpen(!open);
  };

  return (
    <>
      <img
        className="mobile-navbar__icon"
        src={HamburgerIcon}
        onClick={openNavbar}
      />
      <AnimatePresence exitBeforeEnter>
        {open && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.3 }}
          >
            <div className="mobile-navbar__menu">
              <img
                className="mobile-navbar__menu__close"
                src={Close}
                onClick={openNavbar}
              />
              <div className="mobile-navbar__menu__links">
                <nav
                  className="container navbar container-md-padding"
                  style={{ position: "relative" }}
                >
                  <div className="navbar__logo">
                    <a
                      href="/"
                      className="block lg:hidden w-[1rem] mt-[0.84rem] ml-1"
                    >
                      <img src={Logo} alt="The Design Release" />
                    </a>
                  </div>
                </nav>
                <div className="pl-4">
                  <a href="/">Home</a>
                  <a href="/events">Events</a>
                  <a href="/works">Works</a>
                  {/* <a href="/jobs">Jobs</a> */}
                  <a href="/in-sites">IN&#8212;SITES Journal</a>
                  <a href="mailto:julia@thedesignrelease.com">Contact</a>
                </div>
                <div className="footer">
                  {/* <nav className="container" style={{ position: "relative" }}>
                    <div className="navbar__logo">
                      <a href="/">
                        <img src={Logo} alt="The Design Release" />
                      </a>
                    </div>
                  </nav> */}

                  <div className="grid grid-cols-2 gap-4 md:hidden">
                    <div className="footer__section__left">
                      <div className="footer__heading">Information</div>
                      <a className="footer__link">
                        <span>About Us</span>
                      </a>
                      <a className="footer__link">
                        <span>Privacy Policy</span>
                      </a>
                      <a className="footer__link">
                        <span>Terms and Conditions</span>
                      </a>
                      <a className="footer__link">
                        <span>Cookie Policy</span>
                      </a>
                      <a className="footer__link" href="/submissions">
                        <span>Submit an Event</span>
                      </a>
                    </div>
                    <div className="footer__section__right md:hidden">
                      <div className="footer__heading">Connect</div>
                      <a
                        className="footer__link"
                        href="https://www.instagram.com/thedesignrelease/"
                      >
                        <span>Instagram</span>
                      </a>
                      <a
                        className="footer__link"
                        href="https://www.linkedin.com/company/thedesignrelease/"
                      >
                        <span>LinkedIn</span>
                      </a>
                      <a
                        className="footer__link"
                        href="https://www.pinterest.com/thedesignrelease/"
                      >
                        <span>Pinterest</span>
                      </a>
                      <a
                        className="footer__link"
                        href="mailto:hello@thedesignrelease.com"
                      >
                        <span>Email</span>
                      </a>
                    </div>
                  </div>

                  <div className="footer__email-form">
                    <div className="footer__heading">Newsletter</div>
                    <form
                      action="https://thedesignrelease.us17.list-manage.com/subscribe/post?u=ffe93e51507b46a26d8e5b829&amp;id=46c7c8421f"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="block validate"
                      target="_blank"
                      noValidate
                    >
                      <div id="mc_embed_signup_scroll" className="">
                        <div className="mc-field-group">
                          <input
                            type="email"
                            placeholder="Enter Email"
                            name="EMAIL"
                            className="block mb-8 w-full text-sm required input sm:mb-4"
                            id="mce-EMAIL"
                            style={{
                              fontSize: "0.75rem !important",
                              padding: "0.25rem 0",
                              fontWeight: 100,
                            }}
                          />
                        </div>
                        <div>
                          <div id="mce-responses" className="clear">
                            <div
                              className="response"
                              id="mce-error-response"
                              style={{ display: "none" }}
                            ></div>
                            <div
                              className="response"
                              id="mce-success-response"
                              style={{ display: "none" }}
                            ></div>
                          </div>
                          <div
                            style={{ position: "absolute", left: "-5000px" }}
                            aria-hidden="true"
                          >
                            <input
                              type="text"
                              name="b_ffe93e51507b46a26d8e5b829_46c7c8421f"
                              tabIndex="-1"
                            />
                          </div>

                          <div className="clear">
                            <input
                              type="submit"
                              value="Subscribe"
                              name="subscribe"
                              id="mc-embedded-subscribe"
                              className="w-full cursor-pointer button light loose-none"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    {/* <div className="footer__copyright font-thin !important:">
                      <div style={{ fontSize: "10px" }}>
                        &copy; 2020 The Design Release Corp. All Rights Reserved.
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileNavbar;
