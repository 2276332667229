import React, { useState, useCallback, useEffect, useRef } from "react";

import Work from "./Work";
import { DimensionsFilter, CategoryFilter } from "./filters";
import { Select, AutoSelect } from "../ui";
import { buildQueryParams } from "../helpers/query";
import client from "../api/client";
import PlusIcon from "../assets/ui/Plus";
import MinusIcon from "../assets/ui/Minus";
import useDeepCompareEffect from "use-deep-compare-effect";
import InfiniteScroll from "react-infinite-scroller";
import GridLoader from "./GridLoader";
import { createTeleporter } from "react-teleporter";

import ModalFilter from "./filters/ModalFilter/ModalFilter";
import { useWindowSize } from "../utils";

import "./EventList.css";

const LEAD_TIME_DATA = [
  {
    name: "1 Week",
    id: "1",
  },
  {
    name: "2 Weeks",
    id: "2",
  },
  {
    name: "3 Weeks",
    id: "3",
  },
  {
    name: "4 Weeks",
    id: "4",
  },
  {
    name: "5 Weeks",
    id: "5",
  },
  {
    name: "6 Weeks",
    id: "6",
  },
  {
    name: "7 Weeks",
    id: "7",
  },
  {
    name: "8 Weeks",
    id: "8",
  },
  {
    name: "9 Weeks",
    id: "9",
  },
  {
    name: "10 Weeks",
    id: "10",
  },
  {
    name: "11 Weeks",
    id: "11",
  },
  {
    name: "12 Weeks",
    id: "12",
  },
  {
    name: "13 Weeks",
    id: "13",
  },
  {
    name: "14 Weeks",
    id: "14",
  },
  {
    name: "15 Weeks",
    id: "15",
  },
  {
    name: "16 Weeks",
    id: "16",
  },
  {
    name: "17 Weeks",
    id: "17",
  },
  {
    name: "18 Weeks",
    id: "18",
  },
  {
    name: "19 Weeks",
    id: "19",
  },
  {
    name: "20 Weeks",
    id: "20",
  },
  {
    name: "+20 Weeks",
    id: "1000",
  },
];

export const WorksFilterModal = createTeleporter();

const WorksList = (props) => {
  const [events, setEvents] = useState(props.events);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const displayModal = () => {
    if (!showFilterModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setShowFilterModal(!showFilterModal);
  };

  const [category, setCategory] = useState({});
  const [dimensions, setDimensions] = useState({});
  const [seller, setSeller] = useState({});
  const [designer, setDesigner] = useState({});
  const [origin, setOrigin] = useState({});
  const [leadTime, setLeadTime] = useState({});
  const [materials, setMaterials] = useState([]);

  const [loading, setLoading] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [donePaginating, setDonePaginating] = useState(false);
  const listRef = useRef();

  const modalItems = [
    {
      type: "select",
      placeholder: "Category",
      value: category,
      options: props.categories,
      method: setCategory,
    },
    {
      type: "select",
      placeholder: "Seller",
      value: seller,
      options: props.sellers,
      method: setSeller,
    },
    {
      type: "select",
      placeholder: "Designer",
      value: designer,
      options: props.designers,
      method: setDesigner,
    },
  ];
  const extraModalItems = [
    {
      type: "dimensions-filter",
      placeholder: "",
      value: dimensions,
      options: "",
      method: setDimensions,
    },
    {
      type: "select",
      placeholder: "Lead Time",
      value: leadTime,
      options: LEAD_TIME_DATA,
      method: setLeadTime,
    },
    {
      type: "auto-select",
      placeholder: "Material",
      value: materials,
      options: props.materials || [],
      method: setMaterials,
    },
    {
      type: "select",
      placeholder: "Origin",
      value: origin,
      options: props.origins,
      method: setOrigin,
    },
  ];

  const getEvents = (page = 0) =>
    client.get(
      "/works" +
        buildQueryParams({
          categories: category.id,
          seller: seller.id,
          designer: designer.id,
          lead_time: leadTime.id,
          materials: materials.length ? materials.join(",") : null,
          origin: origin.id,
          width: dimensions.width ? dimensions.width.join(",") : null,
          height: dimensions.height ? dimensions.height.join(",") : null,
          depth: dimensions.depth ? dimensions.depth.join(",") : null,
          measurement_unit: dimensions.type ? dimensions.type : null,
          page,
        })
    );

  useDeepCompareEffect(() => {
    setLoading(true);

    getEvents().then(({ data }) => {
      setDonePaginating(false);
      setLoading(false);
      setEvents(data);
    });
  }, [seller, designer, category, origin, leadTime, materials, dimensions]);

  const toggleMoreFilters = useCallback(
    () => setFiltersOpen((state) => !state),
    []
  );

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ModalFilter
        pageName="Works"
        hideModal={displayModal}
        items={modalItems}
        extraItems={extraModalItems}
        open={showFilterModal}
        filtersOpen={filtersOpen}
        toggleMoreFilters={toggleMoreFilters}
        // showMap={showMap}
        // setShowMap={setShowMap}
      ></ModalFilter>
      <div className="event-list" ref={listRef}>
        <div className="block pt-2 event-list__filters md:grid">
          {useWindowSize().width < 768 ? (
            <button
              className="button tracking-widest block md:hidden"
              alt="Filter Works"
              onClick={displayModal}
            >
              Filter Works
            </button>
          ) : (
            <>
              <CategoryFilter
                categories={props.categories}
                onChange={(v) => {
                  setCategory(v);
                }}
              />
              <Select
                placeholder="Seller"
                options={props.sellers}
                onChange={(v) => {
                  setSeller(v);
                }}
              />
              <Select
                placeholder="Designer"
                options={props.designers}
                onChange={(v) => {
                  setDesigner(v);
                }}
              />
              <div
                className="flex justify-between font-thin event-list__toggle_map"
                onClick={toggleMoreFilters}
              >
                <span>More Filters</span>
                <span>
                  {filtersOpen ? (
                    <MinusIcon
                      style={{
                        fill: "#9c9c9c",
                        marginTop: "3px",
                        transform: "translateX(2px)",
                      }}
                    />
                  ) : (
                    <PlusIcon
                      style={{
                        fill: "#9c9c9c",
                        marginTop: "2px",
                      }}
                    />
                  )}
                </span>
              </div>

              <DimensionsFilter
                className={
                  "collecting-page-filter " + (filtersOpen ? "" : "hidden")
                }
                onChange={(v) => {
                  setDimensions(v);
                }}
              />
              <Select
                className={
                  "collecting-page-filter " + (filtersOpen ? "" : "hidden")
                }
                placeholder="Lead Time"
                options={LEAD_TIME_DATA}
                onChange={(v) => {
                  setLeadTime(v);
                }}
              />
              <AutoSelect
                className={
                  "collecting-page-filter " + (filtersOpen ? "" : "hidden")
                }
                selectorId={(v) => v.id}
                placeholder="Material"
                options={props.materials || []}
                onChange={(v) => {
                  setMaterials(
                    v.filter((x) => x.checked === true).map((x) => x.id)
                  );
                }}
              />
              {/* <ColorsFilter className="collecting-page-filter--origin" placeholder="Color" options={COLORS_DATA} onChange={(v) => setOrigin(v)} /> */}
              <Select
                className={
                  "collecting-page-filter " + (filtersOpen ? "" : "hidden")
                }
                placeholder="Origin"
                options={props.origins}
                onChange={(v) => {
                  setOrigin(v);
                }}
              />
              <div style={{ gridArea: "span 4 / span 4" }}>
                <WorksFilterModal.Target
                  style={{
                    position: "absolute",
                    zIndex: 999999,
                    width: "100%",
                    background: "var(--colors-white)",
                    marginTop: "-1px",
                  }}
                />
              </div>
            </>
          )}
        </div>

        <>
          {!loading && events && events.length === 0 && (
            <div className="event-list__events">
              <div className="event-list__no-events">No Works Found</div>
            </div>
          )}
          {loading && (
            <div className="event-list__events">
              <div className="event-list__no-events">
                <GridLoader />
              </div>
            </div>
          )}
          {!loading && (
            <InfiniteScroll
              className="event-list__events"
              pageStart={0}
              loadMore={(_page) => {
                getEvents(_page).then(({ data }) => {
                  setEvents(events.concat(data));
                  if (data.length !== 16) setDonePaginating(true);
                });
              }}
              hasMore={!donePaginating}
              loader={<GridLoader key={-1} padded={true} />}
              threshold={-100}
            >
              {events.map((event) => (
                <Work key={event.id} event={event} />
              ))}
            </InfiniteScroll>
          )}
        </>
      </div>
    </>
  );
};

export default WorksList;
