import moment from "moment";

export default class EventHelper {
  static when(event) {
    const starts = moment.utc(event.starts);
    const ends = moment.utc(event.ends);

    return `${starts.date()} ${starts.format("MMM")}${
      starts.year() !== ends.year() ? `, ${starts.year()}` : " "
    } - ${ends.date()} ${ends.format("MMM")}, ${ends.year()}`;
  }

  static buildCalendarUrl(event, venue) {
    // http://www.google.com/calendar/event?action=TEMPLATE&dates=20180821%2F20180822&text=Litmus%20Live%20London&location=etc.venues%20155%20Bishopsgate&details=Litmus%20Live%20helps%20you%20become%20a%20better%20email%20marketer%20with%20real-world%20advice%2C%20best%20practices%20%26%20practical%20takeaways."

    return `http://www.google.com/calendar/event?action=TEMPLATE&dates=${moment(
      event
    ).unix()}%2f${moment(event).unix()}&location=${venue.address}&description=${
      event.description
    }&title=${event.title}`;
  }

  static daysAgo(date, dayThreshold = 14) {
    const now = moment();
    const days = now.diff(date, "days");

    if (days >= dayThreshold)
      return `${date.date()} ${date.format("MMM")}, ${date.year()}`;

    return days >= 1
      ? `${days} day${days > 1 ? "s" : ""} ago`
      : `Today` + ` @ ${moment(date).format("hh:mm:ss a")}`;
  }
}
