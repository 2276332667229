import React from "react";

import Select from "../ui/v3/Select";
import { useEventsStore } from "../state";

import "./NavBarFilters.css";

const NavBarFilters = ({ isEvents = false, cities, dates }) => {
  const { city, setCity, date, setDate } = useEventsStore();

  return (
    <>
      {isEvents ? (
        <div className="nav-bar-filters">
          <div className="flex justify-end">
            <Select
              placeholder="Where"
              options={cities}
              onChange={(v) => {
                setCity(v);
              }}
              value={city.name}
              dropdownPosition="right"
              dropdownWidth="full"
            />
          </div>

          <div className="flex justify-start">
            <Select
              placeholder="When"
              options={dates}
              onChange={(v) => {
                setDate(v);
              }}
              value={date.name}
              dropdownWidth="full"
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default NavBarFilters;
