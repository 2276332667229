const TYPE_DIVIDER = 2.54;

const CM_TYPE = 'CM';

const convertINtoCM = (value, typeToConvert) => {
  if (typeToConvert === CM_TYPE) {
    return value * TYPE_DIVIDER;
  }

  return value / TYPE_DIVIDER;
};

export default convertINtoCM;
