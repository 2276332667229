import React, { useState } from "react";
import { Portal } from "react-portal";
import StickyBox from "react-sticky-box";
import sanitizeHtml from "sanitize-html";
import { ReactSVG } from "react-svg";
import moment from "moment";

import "./EventDetail.css";
import Calendar from "../../assets/ui/calendar.svg";
import Clock from "../../assets/ui/clock-circle.svg";
import MapPoint from "../../assets/ui/map-point.svg";
import Info from "../../assets/ui/info-circle.svg";
import Global from "../../assets/ui/global.svg";

const formatDate = (date) => {
  return moment.utc(date).format("MMMM D, YYYY");
};

function SideBar({ event, venue, organizer } = props) {
  return (
    <div>
      {/* Event info */}
      <div className="detail-v3__name">{event.title}</div>
      <div className="detail-v3__venue mb-[3.333rem]">by {organizer.name}</div>
      {/* Dates */}
      <div className="detail-v3__card">
        <div className="detail-v3__card__icon">
          <img src={Calendar} alt="calendar" />
        </div>
        <div className="flex lg:justify-between flex-grow">
          <div className="mr-8 lg:mr-0">
            <div className="detail-v3__card__header">Start Date</div>
            <div className="detail-v3__card__content leading-6">
              {formatDate(event.starts)}
            </div>
          </div>
          <div>
            <div className="detail-v3__card__header">End Date</div>
            <div className="detail-v3__card__content leading-6">
              {formatDate(event.ends)}
            </div>
          </div>
        </div>
      </div>
      {/* Hours */}
      <div className="detail-v3__card">
        <div className="detail-v3__card__icon">
          <img src={Clock} alt="clock" />
        </div>
        <div className="flex justify-between flex-grow">
          <div>
            <div className="detail-v3__card__header">Hours</div>
            <div className="detail-v3__card__content">
              {event.hours.split("\n").map((line) => (
                <div className="leading-6">{line}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Venue location */}
      <div className="detail-v3__card detail-v3__card__location">
        <div className="detail-v3__card__icon w-10">
          <ReactSVG src={MapPoint} />
        </div>
        <div className="flex justify-between flex-grow">
          <a
            href={`https://maps.google.com/?q=${venue.name} ${venue.address}`}
            target="_blank"
          >
            <div className="detail-v3__card__header">Location</div>
            <div className="detail-v3__card__content leading-6">
              <div className="font-light">{venue.name}</div>
              <div className="leading-6">{venue.address}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

function Gallery({ event, photos, ...rest }) {
  return (
    <div className={`detail-v3__gallery ${rest.className}`}>
      <div className="detail-v3__gallery__image">
        <img src={photos[0].url} alt={event.title} />
      </div>
    </div>
  );
}

function Description({ event }) {
  return (
    <div className="detail-v3__description lg:px-[1.62rem]">
      {event.description.split("\n").map((line) => (
        <p>{line}</p>
      ))}
    </div>
  );
}

function OrganizerInfoCard({ organizer, viewMore = false, setViewMore }) {
  const previewDescriptionText = organizer.description
    .split(" ")
    .slice(0, 25)
    .join(" ");

  return (
    <div
      className={`detail-v3__card max-w-[1544px] mt-12 lg:mt-0 lg:mb-0 ${
        viewMore && "max-h-[90vh] overflow-y-auto"
      }`}
    >
      <div className="detail-v3__card__icon">
        <img src={Info} alt="about" />
      </div>
      <div className="flex justify-between flex-grow">
        <div>
          <div className="detail-v3__card__header">About</div>
          <div className="detail-v3__card__content">
            <div className="font-light">{organizer.name}</div>
            <div className="leading-6 mt-4 mb-16">
              {viewMore ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(organizer.markdownDescription),
                  }}
                  className="markdown-body"
                ></div>
              ) : (
                <>
                  <span>{previewDescriptionText}... </span>
                  <span
                    className="cursor-pointer text-[var(--colors-blue)]"
                    onClick={() => setViewMore(true)}
                  >
                    View More
                  </span>
                </>
              )}
            </div>
            <div className="mt-4">
              {/* Display socials */}
              <a
                href={organizer.website}
                className="detail-v3__card__icon inline-block"
                target="_blank"
              >
                <img src={Global} alt="website" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OrganizerInfo({ organizer }) {
  const [viewMore, setViewMore] = useState(false);

  return (
    <>
      <div>
        <OrganizerInfoCard
          organizer={organizer}
          viewMore={false}
          setViewMore={setViewMore}
        />
      </div>
      <Portal>
        {viewMore && (
          <div
            className="bg-gray-800 bg-opacity-75 fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-[1000] overflow-y-auto p-8 overscroll-contain"
            onClick={() => setViewMore(false)}
          >
            <OrganizerInfoCard
              organizer={organizer}
              viewMore={true}
              setViewMore={setViewMore}
            />
          </div>
        )}
      </Portal>
    </>
  );
}

export default function EventDetail({
  event,
  venue,
  organizer,
  organizerDescription,
  photos,
} = props) {
  // Override organizer description with the markdown'd one.
  organizer.markdownDescription = organizerDescription;

  return (
    <div className="container container-md-padding mx-auto detail-v3">
      <div className="lg:grid lg:grid-cols-8 lg:gap-[1.62rem]">
        <div className="col-span-2">
          <StickyBox offsetTop="100">
            <Gallery
              event={event}
              photos={photos}
              className="block lg:hidden mb-14"
            />
            <SideBar event={event} venue={venue} organizer={organizer} />
          </StickyBox>
        </div>
        <div className="col-span-6">
          <Gallery event={event} photos={photos} className="hidden lg:block" />
          <div className="lg:grid lg:grid-cols-3 lg:gap-[1.62rem]">
            <div className="col-span-2">
              <Description event={event} />
            </div>
            <div>
              <StickyBox offsetTop="100">
                <OrganizerInfo organizer={organizer} />
              </StickyBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
