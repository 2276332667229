import "./Event.css";

import React from "react";

import { useWindowSize } from "../utils";
import LineClamp from "react-line-overflow";

export const descriptionReplacePattern = /(<([^>]+)>|\[([^>]+)\]|&([^>]+);)/gi;

const Work = (props) => {
  const { url, event, onMouseOver, eventRef } = props;

  const windowSize = useWindowSize();

  return (
    <a
      href={"/work/" + props.event.slug}
      className={"event " + props.className}
      onMouseOver={onMouseOver}
      ref={eventRef}
    >
      {event.photos.length && (
        <div
          className="event__photo xs:mt-12"
          style={{
            "--aspect-ratio": "4/3",
            backgroundImage: `url(${event.photos[0].url})`,
          }}
        />
      )}

      {windowSize.width < 768 && url == "/" ? (
        <div>
          <div className="mt-8 mb-1 text-xs tracking-widest event__info">
            <div className="text-lg font-thin block md:hidden">
              {event.name}
            </div>
            <div className="event__subheader block md:hidden self-center">
              {event.price}
            </div>
          </div>
          <div className="mb-8 font-thin tracking-wider event__info">
            <div className="text-base font-thin event__subtitle block md:hidden">
              {event.organizer.name}
            </div>
            <div className="text-xs font-thin block md:hidden">
              {event.designer.name}
            </div>
          </div>

          <div className="my-6">
            <div className="mb-2 mt-2 event__subheader">DESCRIPTION</div>
            <div className="text-sm event_subtitle">
              <LineClamp lines={3} ellipsis="...">
                {event.description.replace(descriptionReplacePattern, "")}
              </LineClamp>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-6 mb-1 text-xs font-light tracking-wider event__info">
            <div>{event.category ? event.category.name : "N/A"}</div>
            <div>{event.designer.name}</div>
          </div>
          <div className="mb-6 font-thin tracking-wider event__info">
            <div>{event.style}</div>
            <div>{event.seller_location}</div>
          </div>

          <div className="text-sm event__title">{event.name}</div>
          <div className="text-sm font-thin event__subtitle">
            {event.organizer.name}
          </div>
        </div>
      )}
    </a>
  );
};

export default Work;
