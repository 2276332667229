import React, { useState } from "react";
import { Portal } from "react-portal";

import "./CategoryPicker.css";

const Categories = ({ categories, onChange, value }) => {
  return (
    <div className="category-picker">
      <div className="category-picker__categories">
        {categories.map((category) => (
          <div
            className={`category-picker__category ${
              value.name == category.name &&
              "category-picker__category--selected"
            }`}
            key={category.id}
            onClick={(e) => {
              // e.preventDefault();
              // e.stopPropagation();

              if (value.name == category.name) onChange({});
              else onChange(category);

              // return false;
            }}
          >
            {category.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const CategoryPicker = ({ categories, onChange, value }) => {
  const [viewMore, setViewMore] = useState(false);

  return (
    <>
      <div className="h-[3.5rem] overflow-hidden flex">
        <div className="hidden lg:block">
          <Categories
            categories={categories}
            onChange={onChange}
            value={value}
          />
        </div>
        <div className="w-full">
          <div
            className={`category-picker__more`}
            onClick={() => setViewMore(true)}
          >
            <span className="hidden lg:block">All Categories</span>
            <span className="lg:hidden">Filter Categories</span>
          </div>
        </div>
      </div>
      <Portal>
        {viewMore && (
          <div
            className="bg-gray-800 bg-opacity-75 fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-[1000] overflow-y-auto p-8 overscroll-contain"
            onScroll={(e) => e.preventDefault()}
            onClick={() => setViewMore(false)}
          >
            <div className="category-picker__modal">
              <Categories
                categories={categories}
                onChange={onChange}
                value={value}
              />
            </div>
          </div>
        )}
      </Portal>
    </>
  );
};

export default CategoryPicker;
