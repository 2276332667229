import "./Event.css";

import EventHelper from "../helpers/event";
import React from "react";
import moment from "moment";

const Job = (props) => {
  const { event, onMouseOver, eventRef } = props;

  return (
    <a
      href={"/job/" + props.event.slug}
      className={"event " + props.className}
      onMouseOver={onMouseOver}
      ref={eventRef}
      target="_blank"
    >
      <div
        className="event__photo"
        style={{
          "--aspect-ratio": "4/3",
          backgroundImage: `url(${
            event.photos.length
              ? event.photos[0].url
              : event.organizer.photo
              ? event.organizer.photo
              : ""
          })`,
        }}
      ></div>
      <div className="mt-6 mb-1 text-xs font-light event__info">
        <div>{event.job_position.title}</div>
        <div>{event.job_type.title}</div>
      </div>
      <div className="mb-6 font-thin event__info">
        <div>{moment(event.created_at).format("DD MMM, YYYY")}</div>
        <div>{event.city.name}</div>
      </div>
      <div className="text-sm event__title">{event.title}</div>
      <div className="text-sm font-thin event__subtitle">
        at {event.organizer.name}
      </div>
    </a>
  );
};

export default Job;
