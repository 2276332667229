import axios from "axios";

const client = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "//localhost:3000/api/v1"
      : `//${window.location.host}/api/v1`,
});

export default client;
