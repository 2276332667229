import React, { useMemo, useState } from "react";
import clsx from "clsx";

import "./Select.css";

import Input from "./Input";
import chevronDown from "../assets/ui/Chevron_Down.svg";
import chevronDownLargeScreen from "../assets/ui/Arrow_White.png";
import closeIcon from "../assets/ui/Close_Dark.svg";
import { useWindowSize } from "../utils";

const Select = ({
  className,
  onChange,
  options = [],
  selector = (v) => v.name,
  customOption,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [value, setValue] = useState(props.value || "");

  const handleChange = (e) => {
    e.preventDefault();
    setSelected(null);

    if (e.target.value.trim().length === 0) setSelected(null);

    setValue(e.target.value);
  };

  const matches = useMemo(
    () =>
      value === ""
        ? options
        : options.filter((v) =>
            selector(v).toLowerCase().includes(value.toLowerCase())
          ),
    [value, options]
  );

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValue("");
    setSelected(null);
    onChange({});
    setOpen(false);
  };

  const handleSelection = (option) => () => {
    setSelected(option);
    setValue(selector(option));
    if (onChange) onChange(option);
  };

  const handleEscapeClear = (e) => {
    if (e.key === "Escape") {
      setValue("");
      setSelected(null);
      onChange({});
    }
  };

  const valueOption = useMemo(
    () => options.find((x) => x.value === value),
    [value]
  );

  const windowSize = useWindowSize();

  return (
    <div
      className={clsx("select", className)}
      onClick={() => setOpen(!open)}
      onMouseLeave={() => setOpen(false)}
    >
      {customOption ? (
        <div className="select-selected">
          {valueOption && valueOption.value
            ? customOption(valueOption)
            : props.placeholder}
        </div>
      ) : (
        <Input
          {...props}
          onChange={handleChange}
          value={value}
          onKeyDown={handleEscapeClear}
        />
      )}
      <div
        className={clsx("select__indicator", {
          close: open || value,
          "select__indicator--custom": customOption,
        })}
      >
        {(open || value !== "") && (
          <img
            src={closeIcon}
            alt="close"
            onClick={handleClear}
            className="close"
          />
        )}
        {!open && value === "" && (
          <img
            src={windowSize.width < 768 ? chevronDown : chevronDownLargeScreen}
            alt={open ? "open" : "closed"}
            className={open ? "open" : ""}
          />
        )}
      </div>
      <div className={"select__dropdown " + (open ? "open" : "")}>
        {(selected ? options : matches).map((option, i) => (
          <div
            key={i}
            className="select__item"
            onClick={handleSelection(option)}
          >
            {customOption ? customOption(option) : selector(option)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
