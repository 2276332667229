import React from "react";
import StickyBox from "react-sticky-box";

const DetailSidebarWork = ({ event, designer, seller }) => {
  return (
    <StickyBox offsetBottom={40} offsetTop={175}>
      <div className="detail__info__info sticky-sidebar">
        <div>
          <div className="mb-2 text-base leading-none detail__title">{event.name}</div>
        </div>
        <div>
          <div>{seller.name}</div>
        </div>
        <div>
          <div className="my-4 detail__info__header-small">Designer</div>
          <div>
            <div>{designer.name}</div>
          </div>
        </div>
        <div>
          <div className="my-4 detail__info__header-small">Year</div>
          <div>
            <div>{event.date_of_manufacture}</div>
          </div>
        </div>
        <div>
          <div className="detail__info__header-small">Origin</div>
          <div>
            <div>{event.seller_location}</div>
          </div>
        </div>
      </div>
    </StickyBox>
  );
};

export default DetailSidebarWork;
