import "./HomeHero.css";
import "./CardCarousel.css";

import { motion, AnimatePresence, useAnimation } from "framer-motion";

import Event from "./Event";
import News from "./News";
import Work from "./Work";
import SelectHero from "../ui/SelectHero";

import React, { useState, useEffect } from "react";

const HomeHero = (props) => {
  const { events, works, news, cities, defaultCity } = props;

  let [currentCards, setCurrentCards] = useState({
    cards: [],
    ComponentType: "",
  });
  let [currentCity, setCurrentCity] = useState(defaultCity);

  const generateRandom = (items, component) => {
    const set = new Set();

    while (set.size < 2) {
      set.add(Math.floor(Math.random() * items.length - 1) + 1);
    }
    const arr = [...set];

    return { cards: [items[arr[0]], items[arr[1]]], ComponentType: component };
  };

  const refreshCards = (component) => {
    let items = { cards: [], ComponentType: "" };
    if (component === "event") {
      items = generateRandom(events, Event);
    } else if (component === "work") {
      items = generateRandom(works, Work);
    } else if (component === "news") {
      items = generateRandom(news, News);
    }
    return items;
  };

  const openCity = (city) => {
    setCurrentCity(city.name);
    window.location.href = `/events/city/${city.code.toLowerCase()}`;
  };

  useEffect(() => {
    setCurrentCards(refreshCards("event"));
    setCurrentCity("New York City");
  }, []);

  return (
    <div className="block grid-cols-2 gap-12 hero md:grid">
      <div className="hero__info">
        <div className="mb-12">
          <h2>
            Discover Art and Design Events in{" "}
            <SelectHero
              className="hero__select"
              onMouseEnter={() => setCurrentCards(refreshCards("event"))}
              options={cities}
              value={currentCity}
              onChange={(v) => {
                openCity(v);
              }}
            />
          </h2>
        </div>
        <div>
          We are a global art and design platform curating a comprehensive
          selection of{" "}
          <a
            href="/events"
            onMouseEnter={() => setCurrentCards(refreshCards("event"))}
          >
            events
          </a>{" "}
          and{" "}
          <a
            href="/works"
            onMouseEnter={() => setCurrentCards(refreshCards("work"))}
          >
            works
          </a>
          . Stay in the loop with our in-house editorial platform{" "}
          <a
            href="/in-sites"
            onMouseEnter={() => setCurrentCards(refreshCards("news"))}
          >
            IN&#8212;SITES Journal
          </a>
          .
        </div>
      </div>

      <div className="hidden grid-cols-2 gap-12 justify-between md:grid">
        <AnimatePresence exitBeforeEnter>
          {currentCards.cards.map((card, index) => (
            <motion.div
              key={card.id || index}
              exit={{
                opacity: 0,
                x: -25,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              initial={{
                opacity: 0,
                // x: 25,
              }}
              transition={{ ease: "easeOut", duration: 0.3 }}
            >
              {
                <currentCards.ComponentType
                  event={card}
                  className="card md:p-0"
                />
              }
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default HomeHero;
