import "./Featured.css";

import React from "react";
import { motion, useAnimation } from "framer-motion";
import moment from "moment";

const isButtonEnabled = (jobs) => {
  return jobs.length > 1 ? "button" : "button button--disabled";
};

const FeaturedJobs = (props) => {
  const { jobs, defaultCurrentEvent, nextInterval } = props;

  const [currentEvent, setCurrentEvent] = React.useState(defaultCurrentEvent);

  const controls = useAnimation();

  const next = async () => {
    await controls.start({ opacity: 0, x: 20, });
    setCurrentEvent((v) => (v + 1) % jobs.length);
    controls.set({ x: 0 });
    await controls.start({ opacity: 1, x: 0, });
  };

  const previous = async () => {
    await controls.start({ opacity: 0, x: -20, });
    if (currentEvent - 1 < 0) {
      setCurrentEvent(() => jobs.length - 1);
    } else {
      setCurrentEvent((v) => v - 1);
    }
    controls.set({ x: 0 });
    await controls.start({
      opacity: 1,

    });
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, nextInterval);

    return () => clearInterval(interval);
  }, []);

  const job = jobs[currentEvent];

  return (
    <motion.div
      animate={controls}
      transition={{ ease: "easeOut", duration: 0.3 }}
    >
      <div className="featured">
        <div className="flex flex-col featured__left">
          <div className="featured__info">
            <div>
              <a href={"/job/" + job.slug}>
                <h2>{job.title}</h2>
                <h3>by {job.organizer.name}</h3>
              </a>
            </div>
            <div>
              <div className="featured__when">
                <div className="featured__header">Position</div>
                <div>{job.job_position.title}</div>
                <div className="featured__header">City</div>
                <div>{job.city.name}</div>
              </div>
            </div>
            <div className="block md:hidden">
              <p className="mb-4 text-sm featured__description">
                {job.description.slice(0, 350)}...
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 12 mt-8 featured__navigation lg:block">
            <button className={isButtonEnabled(jobs)} onClick={previous}>
              Previous
            </button>
            <button className={isButtonEnabled(jobs) + " block lg:hidden"} onClick={next}>
              <div>Next</div>
              <div>
                <div className="button__timer"></div>
              </div>
            </button>
          </div>
        </div>

        <div className="flex flex-col justify-between">
          <p className="hidden featured__description md:block">
            {job.description.slice(0, 350)}...
          </p>
          <button className={isButtonEnabled(jobs) + " hidden lg:block"} onClick={next}>
            <div>Next</div>
            <div>
              <div className="button__timer"></div>
            </div>
          </button>
        </div>

        <div className="featured__right">
          <div className="featured__photo">
            <a href={"/job/" + job.slug}>
              <img src={job.photos[0].url} alt={job.title} />
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
FeaturedJobs.defaultProps = {
  defaultCurrentEvent: 0,
  nextInterval: 15000,
};

export default FeaturedJobs;
