import "./EventsMap.css";

import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";

import Event from "./v3/Event";
import React from "react";
import isEqual from "lodash/isEqual";
import InfiniteScroll from "react-infinite-scroller";
import GridLoader from "./GridLoader";

import markerIcon from "../assets/ui/map-marker.png";
import markerIconSelected from "../assets/ui/map-marker-selected.png";
import style from "../assets/map.style.json";

const nullIsland = { lat: 0, lng: 0 };

class EventsMap extends React.Component {
  initialState = {
    markerBounds: nullIsland,
    map: {},
    previewOpen: false,
    currentMarker: null,
    currentEvent: null,
    hoveredEvent: null,
  };

  state = this.initialState;

  _buildRefs() {
    this.eventRefs = this.props.events.map(() => React.createRef());
    this.scrollRef = React.createRef();
    this.mapRef = React.createRef();
  }

  _mapLoaded(mapProps, map) {
    map.setOptions({
      styles: style,
    });
    this._buildBounds(map);
  }

  _buildBounds(map) {
    if (!map) return;

    const { events, google } = this.props;
    const bounds = new this.props.google.maps.LatLngBounds();

    events
      .filter(
        (event) =>
          event.venue.latitude !== null || event.venue.longitude !== null
      )
      .forEach((event) =>
        bounds.extend({ lat: event.venue.latitude, lng: event.venue.longitude })
      );

    this.setState({
      ...this.state,
      markerBounds: bounds,
      map,
    });

    map.fitBounds(bounds);
  }

  handleEventHover(event) {
    return (e) => {
      this.state.map.panTo({
        lat: event.venue.latitude,
        lng: event.venue.longitude,
      });

      this.state.map.setZoom(12);

      this.setState({ ...this.state, hoveredEvent: event });
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.map && !isEqual(prevProps, this.props)) {
      this._buildBounds(this.state.map);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(
      {
        events: this.props.events,
        currentMarker: this.state.currentMarker,
        hoveredEvent: this.state.hoveredEvent,
      },
      {
        events: nextProps.events,
        currentMarker: nextState.currentMarker,
        hoveredEvent: nextState.hoveredEvent,
      }
    );
  }

  handleMarkerClose(value) {
    this.setState({
      ...this.state,
      previewOpen: value,
      previewOpen: false,
      currentEvent: null,
    });
  }

  handleMarkerClick(event) {
    const self = this;

    return (props, marker, e) => {
      self.setState({
        ...self.state,
        currentMarker: marker,
        previewOpen: true,
        currentEvent: event,
      });
      this.state.map.setCenter({
        lat: this.state.currentEvent.venue.latitude,
        lng: this.state.currentEvent.venue.longitude,
      });
    };
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  componentDidMount() {
    if (!this.props.showMap || !this.mapRef) return;

    // window.scrollTo({
    //   top: this.mapRef.current.offsetTop - 145,
    //   behavior: "smooth",
    // });
  }

  render() {
    const { events } = this.props;
    const { bounds, currentEvent, currentMarker, hoveredEvent } = this.state;

    const { getEvents, setEvents, donePaginating, setDonePaginating } =
      this.props;

    this._buildRefs();

    return (
      <div className="events-map" ref={this.mapRef}>
        {events && events.length === 0 && (
          <div className="event-list__no-events">No Events Found</div>
        )}
        {events && events.length > 0 && (
          <>
            <div ref={this.scrollRef}>
              <InfiniteScroll
                className="events-map__left"
                pageStart={0}
                loadMore={(_page) => {
                  getEvents(_page).then(({ data }) => {
                    setEvents(events.concat(data));
                    if (data.length !== 16) setDonePaginating(true);
                  });
                }}
                hasMore={!donePaginating}
                loader={<GridLoader key={-1} padded={true} />}
                threshold={-100}
              >
                {events.map((event, index) => (
                  <Event
                    event={event}
                    key={event.id}
                    onMouseOver={this.handleEventHover(event)}
                    onMouseOut={() => this.eventRefs[index].current.blur()}
                    eventRef={this.eventRefs[index]}
                  />
                ))}
              </InfiniteScroll>
            </div>
            <Map
              google={this.props.google}
              containerStyle={{
                minHeight: "500px",
                position: "relative",
              }}
              onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
              bounds={bounds}
              mapTypeControl={false}
              streetViewControl={false}
              fullscreenControl={false}
              onClick={this.handleMarkerClose}
              className="events-map__right"
            >
              {events.map((event, index) => (
                <Marker
                  name={event.title}
                  key={event.id}
                  position={{
                    lat: event.venue.latitude,
                    lng: event.venue.longitude,
                  }}
                  icon={{
                    url:
                      hoveredEvent === event ? markerIconSelected : markerIcon,
                    scaledSize: new google.maps.Size(24, 24),
                  }}
                  onMouseover={(e) => {
                    // Remove all other hovers classes
                    this.eventRefs.map((eventRef) =>
                      eventRef.current.classList.remove("hover")
                    );

                    // Smooth scroll to the element
                    this.scrollRef.current.scrollTo({
                      top:
                        this.eventRefs[index].current.offsetTop -
                        this.scrollRef.current.offsetTop,
                      behavior: "smooth",
                    });

                    // Add our hover display class to the current event
                    this.eventRefs[index].current.classList.add("hover");
                  }}
                  onClick={this.handleMarkerClick(event)}
                />
              ))}
              <InfoWindow
                visible={this.state.previewOpen}
                marker={currentMarker}
              >
                {currentEvent ? (
                  <Event event={currentEvent} className="marker" />
                ) : (
                  <></>
                )}
              </InfoWindow>
            </Map>
          </>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBShxOJRL74e3dMmd_Wb7j_oJcRMVP9oV8",
  mapId: "8f0c4b48a7d9fede",
})(React.memo(EventsMap));
