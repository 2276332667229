import React from "react";
import { motion, useAnimation } from "framer-motion";
import moment from "moment";

import EventHelper from "../helpers/event";
import { descriptionReplacePattern } from "./News";

import "./Featured.css";

const isButtonEnabled = (events) => {
  return events.length > 1 ? "button" : "button button--disabled";
};

const FeaturedNews = (props) => {
  const { events, defaultCurrentEvent, nextInterval } = props;

  const [currentEvent, setCurrentEvent] = React.useState(defaultCurrentEvent);

  const controls = useAnimation();

  const next = async () => {
    await controls.start({ opacity: 0, x: 20, });
    setCurrentEvent((v) => (v + 1) % events.length);
    controls.set({ x: 0 });
    await controls.start({ opacity: 1, x: 0, });
  };

  const previous = async () => {
    await controls.start({ opacity: 0, x: -20, });
    if (currentEvent - 1 < 0) {
      setCurrentEvent(() => events.length - 1);
    } else {
      setCurrentEvent((v) => v - 1);
    }
    controls.set({ x: 0 });
    await controls.start({
      opacity: 1,

    });
  };

  React.useEffect(() => {
    // const interval = setInterval(() => {
    //   next();
    // }, nextInterval);

    // return () => clearInterval(interval);
  }, []);

  const event = events[currentEvent];
  const descriptionMaxLength = 350;
  const description = (event.description.replace(descriptionReplacePattern, "").length < descriptionMaxLength) ?
    event.description.replace(descriptionReplacePattern, "") :
    event.description.replace(descriptionReplacePattern, "").slice(0, descriptionMaxLength) + "...";

  return (
    <motion.div
      animate={controls}
      transition={{ ease: "easeOut", duration: 0.3 }}
    >
      <div className="featured">
        <div className="flex flex-col featured__left">
          <div className="featured__info">
            <div>
              <a href={"/event/" + event.slug}>
                <h2>{event.title}</h2>
              </a>
            </div>
            <div>
              <div className="mt-8 featured__when">
                <div className="featured__header">
                  posted
                </div>
                <div>
                  {EventHelper.daysAgo(moment(event.published_date))}
                </div>
                <div className="featured__header">
                  publication
                </div>
                <div>
                  {event.news_aggregate.name}
                </div>
              </div>
            </div>
          </div>
          <div className="featured__navigation">
            <button className={isButtonEnabled(events)} onClick={previous}>
              Previous
            </button>
          </div>
        </div>

        <div className="flex flex-col justify-between">
          <div className="featured__description">
            {description}
          </div>
          <button className={isButtonEnabled(events)} onClick={next}>
            <div>Next</div>
            <div>
              <div className="button__timer"></div>
            </div>
          </button>
        </div>

        <div className="featured__right">
          <div className="featured__photo" style={{ maxHeight: "470px" }}>
            <a href={"/event/" + event.slug}>
              {event.photos.length ? (
                <img src={event.photos[0]} alt={event.title} />
              ) : (
                <div style={{ background: "#f9f8ea", maxHeight: "470px", height: "100vh" }}></div>
              )}
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
FeaturedNews.defaultProps = {
  defaultCurrentEvent: 0,
  nextInterval: 15000,
};

export default FeaturedNews;
