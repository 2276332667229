import "./Event.css";

import EventHelper from "../helpers/event";
import React from "react";
import moment from "moment";

const Card = (props) => {
  const { event, type, url, onMouseOver, eventRef } = props;

  if (type == "news") {
    const categories = event.categories.split(", ");
    let defaultCategory = categories[0];

    if (defaultCategory.toLowerCase() === "main") {
      if (categories[1]) {
        defaultCategory = categories[1];
      } else {
        defaultCategory = "General";
      }
    }

    if (defaultCategory.toLowerCase() === "all") {
      defaultCategory = "General";
    }
  }

  return (
    <a
      href={`/${type}/${props.event.slug}`}
      className={"event " + props.className}
      onMouseOver={onMouseOver}
      ref={eventRef}
      target="_blank"
    >
      <div
        className="event__photo xs:mt-12"
        style={{
          "--aspect-ratio": "4/3",
          backgroundImage:
            type == "events" || type == "work"
              ? `url(${event.photos[0].url})`
              : type == "news"
              ? `url(${
                  event.photos[1] ||
                  event.photos[2] ||
                  event.photos[0] ||
                  event.photos[3] ||
                  event.photos[4]
                })`
              : type == "job"
              ? `url(${
                  event.photos.length
                    ? event.photos[0].url
                    : event.organizer.photo
                    ? event.organizer.photo
                    : ""
                })`
              : "",
        }}
      >
        {moment().isBetween(event.starts, event.ends) && (
          <div className="event__on_view_now">On View Now</div>
        )}
      </div>

      {url == "/" ? (
        <div className="mt-6 text-xs font-light tracking-wider event__info">
          <div className="block sm:hidden">
            {event.categories ? event.categories.split(",")[0] : "N/A"}
          </div>
          <div className="block sm:hidden">{EventHelper.when(event)}</div>

          <div className="hidden sm:block">{EventHelper.when(event)}</div>
          <div className="hidden sm:block">
            {event.categories ? event.categories.split(",")[0] : "N/A"}
          </div>
        </div>
      ) : (
        <div className="mt-6 mb-1 text-xs font-light tracking-wider event__info">
          <div>{EventHelper.when(event)}</div>
          <div>{event.categories ? event.categories.split(",")[0] : "N/A"}</div>
        </div>
      )}

      {url == "/" ? (
        <div className="mb-6 font-thin tracking-wider event__info">
          <div className="block sm:hidden">{event.venue.city.name}</div>
          <div className="block sm:hidden">
            {event.estimated_hours || (
              <span className="italic">See Details For Hours</span>
            )}
          </div>

          <div className="hidden sm:block">
            {event.estimated_hours || (
              <span className="italic">See Details For Hours</span>
            )}
          </div>
          <div className="hidden sm:block">{event.venue.city.name}</div>
        </div>
      ) : (
        <div className="mb-6 font-thin tracking-wider event__info">
          <div>
            {event.estimated_hours || (
              <span className="italic">See Details For Hours</span>
            )}
          </div>
          <div>{event.venue.city.name}</div>
        </div>
      )}

      <div className="text-sm event__title">{event.title}</div>
      <div className="text-sm font-thin event__subtitle">
        by {event.organizer.name}
      </div>
      {/* Removed the description for now
      <div className="mt-4 mb-2 text-sm event__title"> 
        Description
      </div>
      <div className="mb-3 text-sm sm:hidden block">{event.description.substring(0, 200)}...</div> */}
    </a>
  );
};

export default Card;
