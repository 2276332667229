import "./EventsMap.css";

import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";

import Job from "./Job";
import React from "react";
import isEqual from "lodash/isEqual";
import markerIcon from "../assets/ui/map-marker.png";
import style from "../assets/map.style.json";

const nullIsland = { lat: 0, lng: 0 };

class JobsMap extends React.Component {
  initialState = {
    markerBounds: nullIsland,
    map: {},
    previewOpen: false,
    currentMarker: null,
    currentJob: null,
  };

  state = this.initialState;

  _buildRefs() {
    this.jobRefs = this.props.jobs.map(() => React.createRef());
    this.scrollRef = React.createRef();
    this.mapRef = React.createRef();
  }

  _mapLoaded(mapProps, map) {
    map.setOptions({
      styles: style,
    });
    this._buildBounds(map);
  }

  _buildBounds(map) {
    const { jobs, google } = this.props;
    const bounds = new this.props.google.maps.LatLngBounds();

    jobs
      .filter(
        (job) =>
          job.city.latitude !== null || job.city.longitude !== null
      )
      .forEach((job) =>
        bounds.extend({ lat: job.city.latitude, lng: job.city.longitude })
      );

    this.setState({
      ...this.state,
      markerBounds: bounds,
      map,
    });

    map.fitBounds(bounds);
  }

  handleJobHover(job) {
    return (e) => {
      this.state.map.panTo({
        lat: job.city.latitude,
        lng: job.city.longitude,
      });
      this.state.map.setZoom(12);
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.map && !isEqual(prevProps, this.props)) {
      this._buildBounds(this.state.map);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(
      { jobs: this.props.jobs, currentMarker: this.state.currentMarker },
      { jobs: nextProps.jobs, currentMarker: nextState.currentMarker }
    );
  }

  handleMarkerClose(value) {
    this.setState({
      ...this.state,
      previewOpen: value,
      previewOpen: false,
      currentJob: null,
    });
  }

  handleMarkerClick(job) {
    const self = this;

    return (props, marker, e) => {
      self.setState({
        ...self.state,
        currentMarker: marker,
        previewOpen: true,
        currentJob: job,
      });
      this.state.map.setCenter({
        lat: this.state.currentJob.city.latitude,
        lng: this.state.currentJob.city.longitude,
      });
    };
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  componentDidMount() {
    if (!this.props.showMap || !this.mapRef) return;

    window.scrollTo({
      top: this.mapRef.current.offsetTop - 145,
      behavior: "smooth",
    });
  }

  render() {
    const { jobs } = this.props;
    const { bounds, currentJob, currentMarker } = this.state;

    this._buildRefs();

    return (
      <div className="events-map" ref={this.mapRef}>
        {jobs && jobs.length === 0 && (
          <div className="event-list__no-jobs">No Jobs Found</div>
        )}
        {jobs && jobs.length > 0 && (
          <>
            <div className="events-map__left" ref={this.scrollRef}>
              {jobs.map((job, index) => (
                <Job
                  event={job}
                  key={job.id}
                  onMouseOver={this.handleJobHover(job)}
                  onMouseOut={() => this.jobRefs[index].current.blur()}
                  eventRef={this.jobRefs[index]}
                />
              ))}
            </div>
            <Map
              google={this.props.google}
              containerStyle={{
                minHeight: "500px",
                position: "relative",
              }}
              onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
              bounds={bounds}
              mapTypeControl={false}
              streetViewControl={false}
              fullscreenControl={false}
              onClick={this.handleMarkerClose}
            >
              {jobs.map((job, index) => (
                <Marker
                  name={job.name}
                  key={job.id}
                  position={{
                    lat: job.city.latitude,
                    lng: job.city.longitude,
                  }}
                  icon={{
                    url: markerIcon,
                    scaledSize: new google.maps.Size(24, 24),
                  }}
                  onMouseover={(e) => {
                    // Remove all other hovers classes
                    this.jobRefs.map((jobRef) =>
                      jobRef.current.classList.remove("hover")
                    );

                    // Smooth scroll to the element
                    this.scrollRef.current.scrollTo({
                      top:
                        this.jobRefs[index].current.offsetTop -
                        this.scrollRef.current.offsetTop,
                      behavior: "smooth",
                    });

                    // Add our hover display class to the current job
                    this.jobRefs[index].current.classList.add("hover");
                  }}
                  onClick={this.handleMarkerClick(job)}
                />
              ))}
              <InfoWindow
                visible={this.state.previewOpen}
                marker={currentMarker}
              >
                {currentJob ? (
                  <Job event={currentJob} className="marker" />
                ) : (
                  <></>
                )}
              </InfoWindow>
            </Map>
          </>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBShxOJRL74e3dMmd_Wb7j_oJcRMVP9oV8",
  mapId: "8f0c4b48a7d9fede",
})(React.memo(JobsMap));
