import { useState, useMemo, useEffect } from "react";

const useCarousel = (data, dataMap, defaultPageSize = 3) => {
  const [page, setPage] = useState(0);
  const [current, setCurrent] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const pages = useMemo(() => Math.ceil(data.length / pageSize), [pageSize]);

  const previous = () => setPage(page - 1 < 0 ? pages - 1 : page - 1);
  const next = () => setPage(page + 1 > pages - 1 ? 0 : page + 1);

  useEffect(() => {
    if (!dataMap)
      setCurrent(data.slice(page * pageSize, page * pageSize + pageSize));
    else
      setCurrent(
        data.map((card) => _.mapKeys(card, (value, key) => dataMap[key]))
      );
  }, [page, pageSize]);

  return [current, previous, next, page, setPageSize];
};

export default useCarousel;
