import './ParagraphInput.css';

import React, { useState } from "react";

const ParagraphInput = (props) => {
    const [value, setValue] = useState(props.value || "");
    return <input
        maxLength={25}
        type="text"
        style={{ width: props.style.width }}
        className={"paragraph-input " + (props.className ? props.className : "")}
        value={props.value}
        readOnly={props.readOnly}
        onChange={props.onChange}
    />
}

export default ParagraphInput;