import React from "react";

function Minus (props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="10px"
      x="0"
      y="0"
      enableBackground="new 0 0 121.805 121.804"
      version="1.1"
      viewBox="0 0 121.805 121.804"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M7.308 68.211h107.188a7.309 7.309 0 007.309-7.31 7.308 7.308 0 00-7.309-7.309H7.308a7.31 7.31 0 000 14.619z" />
    </svg>
  );
}

export default Minus ;
