import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import './DimensionsFilter.css';
import { SliderFilter } from '../../../ui';
import { convertINtoCM, useLazyEffect } from '../../../utils';
import ModalDropDown from "../../../ui/ModalDropDown";

const MIN = 0;
const MAX = 250;
const CM_TYPE = 'CM';
const IN_TYPE = 'IN';
const TYPE_LABELS = {
  [CM_TYPE]: 'CM',
  [IN_TYPE]: 'IN',
};

//This is quite buggy, Zack I'll definitely need your help here

const DimensionsFilter = ({ className, onChange }) => {

  const mounted = useRef(false);
  const [type, setType] = useState(IN_TYPE);
  const [width, setWidth] = useState([MIN, MAX]);
  const [height, setHeight] = useState([MIN, MAX]);
  const [depth, setDepth] = useState([MIN, MAX]);

  const maxValue = useMemo(() => type === IN_TYPE ? MAX : convertINtoCM(MAX, CM_TYPE), [type]);
  const marks = useMemo(() => [
    {
      value: MIN,
      label: MIN,
    },
    {
      value: type === IN_TYPE ? MAX : convertINtoCM(MAX, CM_TYPE),
      label: type === IN_TYPE ? MAX : convertINtoCM(MAX, CM_TYPE),
    }
  ], [type]);

  const filters = useMemo(() => [
    {
      title: 'Width',
      value: width,
      setValue: setWidth,
    },
    {
      title: 'Height',
      value: height,
      setValue: setHeight,
    },
    {
      title: 'Depth',
      value: depth,
      setValue: setDepth,
    },
  ], [width, height, depth]);

  const toggleType = useCallback(() => setType((state) => state === IN_TYPE ? CM_TYPE : IN_TYPE), []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    const convert = (state) => [
      Math.round(type === IN_TYPE ? convertINtoCM(state[0], IN_TYPE) : convertINtoCM(state[0], CM_TYPE)),
      Math.round(type === IN_TYPE ? convertINtoCM(state[1], IN_TYPE) : convertINtoCM(state[1], CM_TYPE)),
    ];

    setWidth(convert);
    setHeight(convert);
    setDepth(convert);
  }, [type]);

  useLazyEffect(() => {
    if (onChange) onChange({ type, width, height, depth })
  }, [type, width, height, depth])

  return (
    <div className={className + " " + "dimensions-filter"}>
      <ModalDropDown
        placeholder="Dimensions"
        name="categories"
      >
        <div className="dimensions-filter__view">
          {/*<div className="dimensions-filter__toggler">*/}
          {/*  <button className="button" onClick={toggleType} disabled={type === CM_TYPE}>{TYPE_LABELS[CM_TYPE]}</button>*/}
          {/*  <button className="button" onClick={toggleType} disabled={type === IN_TYPE}>{TYPE_LABELS[IN_TYPE]}</button>*/}
          {/*</div>*/}
          <div className="dimensions-filter__sliders">
            {filters.map(({ value, setValue, title }) => (
              <SliderFilter
                key={title}
                className="dimensions-filter__slider"
                title={title}
                startValue={value[0]}
                endValue={value[1]}
                type={TYPE_LABELS[type]}
                sliderProps={{
                  value: value,
                  onChange: (e, value) => setValue(value),
                  defaultValue: 0,
                  'aria-labelledby': "range-slider",
                  min: MIN,
                  max: maxValue,
                  marks: marks,

                }}
              />
            ))}
          </div>
        </div>
      </ModalDropDown>
    </div>
  )
};

export default DimensionsFilter;
