import "./EventList.css";

import React, { useState, useEffect, useRef } from "react";

import EditorialPost from "./EditorialPost";
import EventsMap from "./EventsMap";
import Select from "../ui/Select";
import { buildQueryParams } from "../helpers/query";
import client from "../api/client";
import useDeepCompareEffect from "use-deep-compare-effect";
import InfiniteScroll from "react-infinite-scroller";
import GridLoader from "./GridLoader";
import ModalFilter from "./filters/ModalFilter/ModalFilter";
import { useWindowSize } from "../utils";

import searchIcon from "../assets/ui/search-white.png";
import accountIcon from "../assets/ui/account.png";
import FeaturedEditorialPostInline from "./FeaturedEditorialPostInline";

const EditorialPostsList = (props) => {
  const [events, setEvents] = useState(props.events);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const displayModal = () => {
    if (!showFilterModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setShowFilterModal(!showFilterModal);
  };

  const [author, setAuthor] = useState({});
  const [city, setCity] = useState(props.currentCity || {});
  const [column, setColumn] = useState({});
  const [showMap, setShowMap] = useState(props.showMap || false);
  const [loading, setLoading] = useState(true);
  const [donePaginating, setDonePaginating] = useState(false);
  const listRef = useRef();

  const modalItems = [
    {
      type: "select",
      placeholder: "Column",
      value: column,
      options: props.columns,
      method: setColumn,
    },
    {
      type: "select",
      placeholder: "Author",
      value: author,
      options: props.authors,
      method: setAuthor,
    },
    {
      type: "select",
      placeholder: "City Guides",
      value: author,
      options: props.cities,
      method: setCity,
    },
  ];

  const getEditorialPosts = (page = 0) =>
    client.get(
      "/in-sites" +
        buildQueryParams({
          author: author.id,
          column: column.id,
          city: city.code,
          page,
        })
    );

  useDeepCompareEffect(() => {
    setLoading(true);

    getEditorialPosts().then(({ data }) => {
      setDonePaginating(false);
      setLoading(false);
      setEvents(() => data);
    });
  }, [author, column, city]);

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ModalFilter
        pageName="Editorials"
        hideModal={displayModal}
        items={modalItems}
        open={showFilterModal}
        showMap={showMap}
        setShowMap={setShowMap}
      >
        <div
          className="flex justify-between mb-8 event-list__toggle_map"
          onClick={() => {
            window.location.href = "/news";
          }}
        >
          <span>Industry News</span>
          <span>
            <img
              style={{ width: "1rem", marginTop: 3, marginRight: "0.5rem" }}
              src={searchIcon}
            />
          </span>
        </div>
      </ModalFilter>

      <div className="event-list" ref={listRef}>
        <div
          className="event-list__filters"
          style={{
            display: "grid",
            gap: "2rem",
            "grid-template-columns": "repeat(4, 1fr)",
          }}
        >
          {useWindowSize().width < 768 ? (
            <button
              className="block tracking-widest button md:hidden"
              alt="Filter Editorials"
              onClick={displayModal}
            >
              Filter Editorials
            </button>
          ) : (
            <>
              <Select
                placeholder="Column"
                options={props.columns}
                onChange={(v) => {
                  setColumn(v);
                }}
                value={column.name}
              />
              <Select
                placeholder="Authors"
                options={props.authors}
                onChange={(v) => {
                  setAuthor(v);
                }}
                value={author.name}
              />
              <Select
                placeholder="City Guides"
                options={props.cities}
                onChange={(v) => {
                  setCity(v);
                }}
                value={city.name}
              />
            </>
          )}

          <div
            className="hidden justify-between font-thin event-list__toggle_map md:flex"
            onClick={() => {
              window.location.href = "/news";
            }}
          >
            <span>Industry News</span>
            <span>
              <img
                style={{ filter: "invert(1)", width: "1rem", marginTop: 3 }}
                src={searchIcon}
              />
            </span>
          </div>
        </div>

        {showMap ? (
          <div className="event-list__map">
            <EventsMap events={events} showMap={showMap} />
          </div>
        ) : (
          <>
            {!loading && events && events.length === 0 && (
              <div className="event-list__events">
                <div className="event-list__no-events">No Events Found</div>
              </div>
            )}
            {loading && (
              <div className="event-list__events">
                <div className="event-list__no-events">
                  <GridLoader />
                </div>
              </div>
            )}
            {!loading && (
              <InfiniteScroll
                className="event-list__events"
                pageStart={0}
                loadMore={(_page) => {
                  getEditorialPosts(_page).then(({ data }) => {
                    setEvents((prevData) => {
                      return [...prevData, ...data];
                    });
                    if (data.length < 16) setDonePaginating(true);
                  });
                }}
                hasMore={!donePaginating}
                loader={<GridLoader key={-1} padded={true} />}
                threshold={-200}
              >
                {events.map((event, i) => (
                  <React.Fragment key={i}>
                    {i === 6 && (
                      <FeaturedEditorialPostInline post={events[1]} />
                    )}
                    <EditorialPost
                      event={event}
                      url="/in-sites"
                      className={i < 2 ? "lg:col-span-2" : ""}
                    />
                  </React.Fragment>
                ))}
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EditorialPostsList;
