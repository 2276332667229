import React from 'react';
import clsx from 'clsx';

import './ColorsFilter.css';
import { Select } from '../../../ui';

const ColorsFilter = ({ className, ...props }) => {
  return (
    <Select
      className={clsx('colors-filter-select', className)}
      {...props}
      customOption={({ value }) => (
        <div className="colors-filter-option">
          <div className="colors-filter-option__color" style={{ backgroundColor: value }} />
          {value}
        </div>
      )}
      selector={(x) => x.value}
    />
  )
};

export default ColorsFilter;
