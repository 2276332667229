import React from "react";

import "./KickStart.css";
import Arrow from "../../assets/ui/fancyarrowblue.png";

import StoneChair from "../../assets/images/misc/stone-chair.png";
import StoneHallway from "../../assets/images/misc/stonehallway.png";

const KickStart = () => {
  return (
    <div className="kick-start">
      <a className="kick-start__section" href="/submissions">
        <div className="kick-start__image">
          <img src={StoneChair} />
        </div>
        <div className="kick-start__category">Event Submission</div>
        <div className="kick-start__title w-48">
          Submit your Exhibition <img src={Arrow} />
        </div>
      </a>

      <a className="kick-start__section" href="/in-sites">
        <div className="kick-start__image">
          <img src={StoneHallway} />
        </div>
        <div className="kick-start__category">IN—SITES JOURNAL</div>
        <div className="kick-start__title w-[24.1875rem]">
          Unraveling Stories behind Art and Design <img src={Arrow} />
        </div>
      </a>
    </div>
  );
};

export default KickStart;
