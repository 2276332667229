import React, { useState, useCallback, useMemo } from "react";
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'clsx';

import './DropDown.css';
import { accessor, identity } from "../helpers";
import Chevron from '../assets/ui/Arrow_White.png';

const KEY_SELECTOR_KEY = 'key';
const VALUE_SELECTOR_KEY = 'value';

const DropDown = ({ selected = false, ...props }) => {
  const onChange = useMemo(() => props.onChange || identity, [props.onChange]);
  const keySelector = useMemo(() => props.keySelector || accessor(KEY_SELECTOR_KEY), [props.keySelector]);
  const valueSelector = useMemo(() => props.valueSelector || accessor(VALUE_SELECTOR_KEY), [props.valueSelector]);

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  const handleItemClick = useCallback((e, item) => {
    e.stopPropagation();

    onChange(valueSelector(item));
    setOpen(false);
  }, [onChange]);

  const content = useMemo(() => (
    <div className={cn('dropdown__items', { 'open': open, [props.className]: open })}>
      <div className={cn('dropdown-scrollable', props.classNameScroll)}>
        {props.children}
        {!props.children && (
          <>
            {props.items.map((item, i) => (
              <div key={i} className="dropdown__item" onClick={(e) => handleItemClick(e, item)}>
                {keySelector(item)}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  ), [props.children, props.className, props.items, keySelector, handleItemClick, open]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => handleClose()}
    >
      <div className={cn('dropdown select', { 'dropdown--no-items': !props.children })} tabIndex="0">
        <div className="dropdown__menu" onClick={handleOpen}>
          <div>{selected ? keySelector(selected) : props.placeholder}</div>
          <div className={cn('indicator', { 'open-indicator': open })}>
            <img src={Chevron} />
          </div>
        </div>
        {content}
      </div>
    </OutsideClickHandler>
  )
}

DropDown.propTypes = {
  onChange: PropTypes.func,
  keySelector: PropTypes.func,
  valueSelector: PropTypes.func,
  items: PropTypes.array,
  selected: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  classNameScroll: PropTypes.string,
};

export default DropDown;
