import React, { useCallback, useState, useMemo } from "react";
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutsideClickHandler from 'react-outside-click-handler';

import "../Select.css";
import './AutoSelect.css';

import chevronDownMobile from "../../assets/ui/Chevron_Down.svg";
import chevronDown from "../../assets/ui/Arrow_White.png"
import closeIcon from "../../assets/ui/Close_Dark.svg";
import { useWindowSize } from "../../utils";

const AutoSelect = ({
  className,
  onChange,
  options = [],
  selector = (v) => v.name,
  selectorId = (v) => v.id,
  customOption,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(options.map((x) => ({ ...x, checked: false })));
  const [value, setValue] = useState(props.value || "");

  const handleClear = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    setSelected((state) => state.map((x) => ({ ...x, checked: false })));
    setValue('');

    setOpen(false);

    if (onChange) {
      onChange([]);
    }
  }, []);

  const handleClick = useCallback((e, option) => {
    e.preventDefault();
    e.stopPropagation();

    const newSelected = [...selected];
    const targetId = selected.findIndex((x) => selectorId(x) === selectorId(option));
    const target = { ...newSelected[targetId] };

    target.checked = !target.checked;

    newSelected.splice(targetId, 1, target);

    setSelected(newSelected);
    setValue(newSelected.filter(g => g.checked).map(y => y.name).join(', '));

    if (onChange) {
      onChange(newSelected);
    }
  }, [selected]);

  const windowSize = useWindowSize();

  const dropdown = useMemo(() => (
    <div className={"select__dropdown " + (open ? "open" : "")}>
      {options.length === 0 && (
        <div
          key="0"
          className="select-item autoselect-item"
        >
          No Options
        </div>
      )}
      {selected.map((option, i) => (
        <div
          key={i}
          className="select-item autoselect-item"
        >
          <FormControlLabel
            control={
              <Checkbox classes={{ root: "autoselect__checkbox" }} />
            }
            onClick={(e) => handleClick(e, option)}
            checked={option.checked}
            label={selector(option)}
          />
        </div>
      ))}
    </div>
  ), [selected, open, selector, handleClick]);

  const displayValue = useMemo(() => value || props.placeholder, [value, props.placeholder]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => setOpen(false)}
    >
      <div
        onClick={() => {

          setOpen(!open);

        }}
        className={clsx("select autoselect", className)}
        onMouseLeave={() => setOpen(false)}
      >
        <div className="select-selected autoselect-selected">
          <span>{displayValue}</span>
        </div>
        <div className={clsx('select__indicator', { 'close': open || value, 'select__indicator--custom': customOption })}>
          {(open || value !== '') && (
            <img
              src={closeIcon}
              alt="close"
              onClick={handleClear}
              className="close"
            />
          )}
          {!open && value === "" && (
            <img
              src={windowSize.width < 768 ? chevronDownMobile : chevronDown}
              alt={open ? "open" : "closed"}
              className={open ? "open" : ""}
            />
          )}
        </div>
        {dropdown}
      </div>
    </OutsideClickHandler>
  );
};

export default AutoSelect;
