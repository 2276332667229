import "./Event.css";
import "./News.css";

import EventHelper from "../helpers/event";
import React from "react";
import moment from "moment";

import { useWindowSize } from "../utils";
import LineClamp from "react-line-overflow";

export const descriptionReplacePattern = /(<([^>]+)>|\[([^>]+)\]|&([^>]+);)/gi;

const News = (props) => {
  const { event, url, onMouseOver, eventRef } = props;

  // Ignore Main category or classify as general
  const categories = event.categories.split(", ");
  let defaultCategory = categories[0];

  if (defaultCategory.toLowerCase() === "main") {
    if (categories[1]) {
      defaultCategory = categories[1];
    } else {
      defaultCategory = "General";
    }
  }

  if (defaultCategory.toLowerCase() === "all") {
    defaultCategory = "General";
  }

  const windowSize = useWindowSize();

  return (
    <a
      href={event.link}
      className={"event " + props.className}
      onMouseOver={onMouseOver}
      ref={eventRef}
      target="_blank"
    >
      <div
        className="event__photo xs:mt-12"
        style={{
          "--aspect-ratio": "4/3",
          backgroundImage: `url(${
            event.photos[1] ||
            event.photos[2] ||
            event.photos[0] ||
            event.photos[3] ||
            event.photos[4]
          })`,
        }}
      ></div>
      {windowSize.width < 768 && url == "/" ? (
        <div>
          <div className="my-6 text-lg font-thin">
            <LineClamp lines={2} ellipsis="...">
              {event.title}
            </LineClamp>
          </div>

          <div className="mt-4">
            <div className="mb-2 mt-2 event__subheader">DESCRIPTION</div>
            <div className="text-sm event_subtitle">
              <LineClamp lines={3} ellipsis="...">
                {event.description.replace(descriptionReplacePattern, "")}
              </LineClamp>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-6 mb-1 text-xs font-light event__info">
            <div>
              {defaultCategory
                .trim()
                .split(" ")
                .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                .join(" ")}
            </div>
            <div>{event.news_aggregate.name}</div>
          </div>
          <div className="mb-6 font-thin event__info ">
            <div>{EventHelper.daysAgo(moment(event.published_date))}</div>
            <div>{event.news_aggregate.country}</div>
          </div>
          <div className="my-6 text-sm event__title">
            <LineClamp lines={2} ellipsis="...">
              {event.title}
            </LineClamp>
          </div>

          <div className="text-sm font-thin event_subtitle">
            <LineClamp lines={3} ellipsis="...">
              {event.description.replace(descriptionReplacePattern, "")}
            </LineClamp>
          </div>
        </div>
      )}
    </a>
  );
};

export default News;
