import './Toggle.css';

import React, { useState } from "react";
import Open from '../assets/ui/Arrow_White.png'
import Close from '../assets/ui/Close_Dark.svg'

const Toggle = (props) => {
    const [value, setValue] = useState(props.value || "");

    return (<div className="toggle">
        <button

            className="tracking-widest block"
        >
            <div className="flex flex-row justify-between items-center w-full">
                <div>
                    {props.placeholder}
                </div>
                <div className="toggle__indicator">

                    <img className="toggle__icon" src={value ? Close : Open} alt={value ? 'Active' : 'Disabled'} />
                </div>
            </div>
        </button>
    </div>)
}

export default Toggle;