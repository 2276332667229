import "./Event.css";

import EventHelper from "../helpers/event";
import React from "react";
import moment from "moment";
import { useWindowSize } from "../utils";

const Event = (props) => {
  const { event, url, onMouseOver, eventRef } = props;

  const windowSize = useWindowSize();

  const href = "/event/" + props.event.slug;

  return (
    <a
      href={href}
      className={"event " + props.className}
      onMouseOver={onMouseOver}
      onClick={(event) => {
        if (props.noLink) {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }}
      ref={eventRef}
    >
      <div
        className="event__photo xs:mt-12"
        style={{
          "--aspect-ratio": "4/3",
          backgroundImage: `url(${event.photos[0].url})`,
        }}
      >
        {moment().isBetween(event.starts, event.ends) && (
          <div className="event__on_view_now">On View Now</div>
        )}
      </div>

      {url == "/" && windowSize.width < 768 ? (
        <div className="content-center mt-8 mb-2 text-xs tracking-widest event__info">
          <div className="block text-lg font-thin md:hidden">{event.title}</div>
          <div className="block self-center event__subheader md:hidden">
            DATES
          </div>
        </div>
      ) : url == "/" && windowSize.width >= 768 ? (
        <div className="mt-6 text-xs font-light tracking-wider event__info">
          <div className="hidden md:block">
            {event.categories ? event.categories.split(",")[0] : "N/A"}
          </div>
          <div className="hidden md:block">{EventHelper.when(event)}</div>
        </div>
      ) : (
        <div className="mt-6 mb-1 text-xs font-light tracking-wider event__info">
          <div>{EventHelper.when(event)}</div>
          <div>{event.categories ? event.categories.split(",")[0] : "N/A"}</div>
        </div>
      )}

      {url == "/" && windowSize.width < 768 ? (
        <div className="mb-6 tracking-wider font-thin event__info">
          <div className="block text-xs font-thin event__subtitle md:hidden">
            {event.organizer.name}
          </div>
          <div className="block text-xs font-thin md:hidden">
            {EventHelper.when(event)}
          </div>
        </div>
      ) : url == "/" && windowSize.width >= 768 ? (
        <div className="mb-6 tracking-wider font-thin event__info">
          <div className="hidden md:block">
            {event.estimated_hours || (
              <span className="italic">See Details For Hours</span>
            )}
          </div>
          <div className="hidden md:block">{event.venue.city.name}</div>
        </div>
      ) : (
        <div className="mb-6 tracking-wider font-thin event__info">
          <div>
            {event.estimated_hours || (
              <span className="italic">See Details For Hours</span>
            )}
          </div>
          <div>{event.venue.city.name}</div>
        </div>
      )}

      {url == "/" ? (
        <div className="py-4">
          <div className="mb-2 tracking-widest event__subheader">
            DESCRIPTION
          </div>
          <div className="block text-sm sm:hidden">
            {event.description.substring(0, 160)}...
          </div>
        </div>
      ) : (
        <div>
          <div className="text-sm event__title">{event.title}</div>
          <div className="text-sm font-thin event__subtitle">
            by {event.organizer.name}
          </div>
        </div>
      )}
    </a>
  );
};

export default Event;
