import "./EventList.css";

import React, { useState, useEffect, useRef } from "react";

import { buildQueryParams } from "../helpers/query";
import CategoryPicker from "./v3/CategoryPicker";
import client from "../api/client";
import Event from "./v3/Event";
import EventsMap from "./EventsMap";
import GridLoader from "./GridLoader";
import InfiniteScroll from "react-infinite-scroller";
import ModalFilter from "./filters/ModalFilter/ModalFilter";
import useDeepCompareEffect from "use-deep-compare-effect";

import { useEventsStore } from "../state";
import { MapToggle } from "./v3/MapToggle";

const EventList = (props) => {
  const [events, setEvents] = useState(props.events);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const displayModal = () => {
    if (!showFilterModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setShowFilterModal(!showFilterModal);
  };

  const {
    city,
    setCity,
    date,
    setDate,
    category,
    setCategory,
    showMap,
    setShowMap,
  } = useEventsStore();

  const [loading, setLoading] = useState(true);
  const [donePaginating, setDonePaginating] = useState(false);
  const listRef = useRef();

  const modalItems = [
    {
      type: "select",
      placeholder: "City",
      value: city,
      options: props.cities,
      method: setCity,
    },
    {
      type: "select",
      placeholder: "Dates",
      value: date,
      options: props.dates,
      method: setDate,
    },
    {
      type: "select",
      placeholder: "Categories",
      value: category,
      options: props.categories,
      method: setCategory,
    },
  ];

  const getEvents = (page = 0) => {
    return client.get(
      "/events" +
        buildQueryParams({
          city: city.code,
          starts: date.value && date.value.starts,
          ends: date.value && date.value.ends,
          category: category.id,
          page,
        })
    );
  };

  useDeepCompareEffect(() => {
    setLoading(true);

    getEvents().then(({ data }) => {
      setDonePaginating(false);
      setLoading(false);
      setEvents(data);
    });
  }, [city, date, category]);

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ModalFilter
        pageName="Events"
        hideModal={displayModal}
        items={modalItems}
        open={showFilterModal}
        showMap={showMap}
        setShowMap={setShowMap}
      ></ModalFilter>

      <MapToggle showMap={showMap} setShowMap={setShowMap} />

      <div className="event-list" ref={listRef}>
        <div className="event-list__filters">
          <CategoryPicker
            categories={props.categories}
            onChange={(v) => setCategory(v)}
            value={category}
          />
        </div>

        {showMap ? (
          <div className="event-list__map">
            <EventsMap
              events={events}
              showMap={showMap}
              loading={loading}
              getEvents={getEvents}
              setEvents={setEvents}
              donePaginating={donePaginating}
              setDonePaginating={setDonePaginating}
            />
          </div>
        ) : (
          <>
            {!loading && events && events.length === 0 && (
              <div className="event-list__events">
                <div className="event-list__no-events">No Events Found</div>
              </div>
            )}
            {loading && (
              <div className="event-list__events">
                <div className="event-list__no-events">
                  <GridLoader />
                </div>
              </div>
            )}
            {!loading && (
              <InfiniteScroll
                className="event-list__events"
                pageStart={0}
                loadMore={(_page) => {
                  getEvents(_page).then(({ data }) => {
                    setEvents(events.concat(data));
                    if (data.length !== 16) setDonePaginating(true);
                  });
                }}
                hasMore={!donePaginating}
                loader={<GridLoader key={-1} padded={true} />}
                threshold={-100}
              >
                {events.map((event) => (
                  <Event key={event.id} event={event} url="/events" />
                ))}
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EventList;
