import React, { } from 'react';
import clsx from 'clsx';
import Slider from '@material-ui/core/Slider';

import './SliderFilter.css';

const SliderFilter = ({ className, title, type, startValue, endValue, sliderProps }) => {
  return (
    <div className={clsx('slider-filter', className)}>
      <span className="featured__header slider-filter__title">{title}</span>
      <span className="slider-filter__subtitle">{startValue} - {endValue} {type}</span>
      <Slider {...sliderProps} classes={{ marked: 'slider-filter__color' }} />
    </div>
  )
};

SliderFilter.props = {

};

export default SliderFilter;
