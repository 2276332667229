import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

import Event from "./Event";
import News from './News';
import Job from "./Job";
import Work from "./Work";
import useCarousel from "../hooks/carousel";
import { useWindowSize } from "../utils";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import "./CardCarousel.css";


const CardCarousel = ({ title, description, data, viewAllUrl, component, url }) => {
  const windowSize = useWindowSize();

  let [page, previous, next, offset, setPageSize] = useCarousel(data.length > 0 ? windowSize.width > 640 ? data : data.slice(0, 5) : data, null, window.innerWidth > 430 ? 3 : 1);

  useEffect(() => {
    setPageSize(windowSize.width > 768 ? 3 : 1);
  }, [windowSize.width]);

  let [currentSlide, setSlide] = useState(0);

  let ComponentType;

  if (component === "event") {
    ComponentType = Event;
  } else if (component === "news") {
    ComponentType = News;
  } else if (component === "jobs") {
    ComponentType = Job;
  } else if (component === "works") {
    ComponentType = Work;
  }

  let nextSlide = () => {
    setSlide(currentSlide + 1 <= 4 ? currentSlide + 1 : currentSlide = 0);
  };

  let previousSlide = () => {
    setSlide(currentSlide >= 0 ? currentSlide - 1 : 4);
  };

  let updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setSlide(currentSlide = index);
    }
  };

  return (
    <div className="mb-16 card-carousel md:mb-0">
      <div className="card-carousel__info xs:mt-2">
        <div className="xs:flex xs:justify-between xs:items-center md:block">
          <div className="card-carousel__info__heading">{title}</div>
          <div className="hidden mr-8 text-sm card-carousel__info__description lg:block">{description}</div>
          {viewAllUrl && (
            <div className="text-sm card-carousel__info__view-all">
              <a href={viewAllUrl} alt="View All">
                View All
              </a>
            </div>
          )}
        </div>
        <div className="card-carousel__buttons">
          <button
            className="card-carousel__previous button"
            alt="Previous"
            onClick={previous}
          >
            &lt;
          </button>
          <button
            className="card-carousel__next button"
            alt="Next"
            onClick={next}
          >
            &gt;
          </button>
        </div>
      </div>

      <AnimatePresence exitBeforeEnter>
        {windowSize.width < 768 ? <Carousel className="mb-4" animationHandler="fade" swipeable={false} showThumbs={false} showStatus={false} showArrows={false} autoPlay={false} infiniteLoop={true} selectedItem={currentSlide}
          onChange={updateCurrentSlide}

          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const indicatorStyles = {
              background: '#ffffff',
              borderColor: '#A8A8A8',
              borderWidth: 0.5,
              width: 8,
              height: 8,
              display: 'inline-block',
              margin: '0 8px',
              borderRadius: '100%'
            };
            const styles = isSelected ? { ...indicatorStyles, backgroundColor: '#000000', borderColor: '#000000' } : { ...indicatorStyles }
            if (isSelected) {
              return (
                <li
                  style={styles}

                />
              );
            }
            return (
              <li
                style={styles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}

              />
            );
          }}
        >
          {data.slice(0, 5).map((card, index) => (
            <motion.div
              key={card.id || index}
              exit={{
                opacity: 0,
                x: -25,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              initial={{
                opacity: 0,
                // x: 25,
              }}
              transition={{ ease: "easeOut", duration: 0.3 }}
            >
              <ComponentType style="margin-bottom: 4rem;" event={card} url={url} className="card mb-5 text-left" />
            </motion.div>
          ))}
        </Carousel > :
          page.map((card, index) => (
            <motion.div
              key={card.id || index}
              exit={{
                opacity: 0,
                x: -25,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              initial={{
                opacity: 0,
                // x: 25,
              }}
              transition={{ ease: "easeOut", duration: 0.3 }}
            >
              <ComponentType event={card} className="card" />
            </motion.div>
          ))
        }


      </AnimatePresence>



      <div className="card-carousel__mobile-navigation">
        <button
          className="button tracking-widest"
          alt="Previous"
          onClick={
            previousSlide
          }
        >
          Previous
          {/* The SVG elements are just basic arrows but it doesn't really look that good imo */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg> */}

        </button>
        <button
          className="button tracking-widest"
          alt="Next"
          onClick={nextSlide}
        >
          Next
          {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg> */}
        </button>
      </div>
    </div>
  );
};

export default CardCarousel;
